import React from 'react';

import {
  Box,
  Container,
  makeStyles,
  Typography,
  Link,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from '@material-ui/core';

import Page from 'src/components/Page';

import ShowDebug from 'src/components/ShowDebug';

import EditRow from './EditRow';

import { getAllAdvertisementTag } from 'src/modals/advertisement_tag';

import active_lang from 'src/langs/jp_en';

import Toolbar from './Toolbar';

import { useSnackbar } from 'notistack';

import ConfirmDeleteAdvertisementTags from './ConfirmDeleteAdvertisementTags';

import test_data from './test_data';
import init_new_advertisement_tag from './init_new_advertisement_tag';

import {
  loadAdvertisementFoodDetails,
  ADVERTISEMENT_FOOD_DETAIL_TEST,
} from 'src/modals/advertisement_food_details';

import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function ListAdvertisementRestaurantTags({
  table_body,
  refreshAddAdvertisementTag,
}) {
  const [advertisement_tag_to_edit, setAdvertisementTagToEdit] = React.useState(
    null
  );
  const [open_edit, setOpenEdit] = React.useState(false);

  const handleEditClick = (e, advertisement_tag) => {
    setAdvertisementTagToEdit(advertisement_tag);
    setOpenEdit(true);
  };

  const handleDeleteClick = (e, advertisement_tag) => {
    setAdvertisementTagToDelete(advertisement_tag);
    setOpenDelete(true);
  };

  const [open_delete, setOpenDelete] = React.useState(false);
  const [
    advertisement_tag_to_delete,
    setAdvertisementTagToDelete,
  ] = React.useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <>
      <ConfirmDeleteAdvertisementTags
        open={open_delete}
        setOpen={setOpenDelete}
        advertisement_tag_to_delete={advertisement_tag_to_delete}
        setAdvertisementTagToDelete={setAdvertisementTagToDelete}
        refreshAddAdvertisementTag={refreshAddAdvertisementTag}
      />
      <EditRow
        open={open_edit}
        setOpen={setOpenEdit}
        advertisement_tag_to_edit={advertisement_tag_to_edit}
        refreshAddAdvertisementTag={refreshAddAdvertisementTag}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{active_lang.ADVERTISEMENT_TAG_ACTIONS}</TableCell>
            <TableCell>{active_lang.ADVERTISEMENT_TAG_TITLE}</TableCell>
            <TableCell>{active_lang.ADVERTISEMENT_TAG_REMARKS}</TableCell>
            {/*
            <ShowDebug>
              <TableCell>debug</TableCell>
            </ShowDebug>
            */}
          </TableRow>
        </TableHead>
        <TableBody>
          {table_body.sort().map((row, idx) => {
            return (
              <>
                <TableRow key={row.id} name={row.id} hover>
                  <TableCell>#{idx + 1}</TableCell>
                  <TableCell>
                    <IconButton onClick={e => handleEditClick(e, row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={e => {
                        handleDeleteClick(e, row);
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.data.title}</TableCell>
                  <TableCell>{row.data.remarks}</TableCell>
                  {/*
                  <ShowDebug>
                    <TableCell>
                      <pre>{JSON.stringify(row)}</pre>
                    </TableCell>
                  </ShowDebug>
                  */}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default function AdvertisementRestaurantTags() {
  const classes = useStyles();
  const [debug, setDebug] = React.useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let [
    advertisement_restaurant_tags,
    setAdvertisementRestaurantTags,
  ] = React.useState(null);

  const handleListAllAdvertisementRestaurantTags = () => {};

  const refreshAddAdvertisementTag = () => {
    getAllAdvertisementTag()
      .then(ss => {
        let a_d = [];
        ss.forEach(doc => a_d.push({ id: doc.id, data: doc.data() }));
        return a_d;
      })
      .then(a_d => {
        setAdvertisementRestaurantTags(a_d);
      })
      .catch(err => {
        enqueueSnackbar('error during saving advertisement tag', {
          variant: 'error',
        });
        if (process.env.NODE_ENV == 'development') {
          throw err;
        }
      });
  };

  React.useEffect(() => {
    refreshAddAdvertisementTag();
  }, []);

  return (
    <>
      <Page
        className={classes.root}
        title={active_lang.ADVERTISEMENT_RESTAURANT_TAGS}
      >
        <Container maxWidth={false}>
          <ShowDebug>
            <Box style={{ textAlign: 'center' }}>
              <Button onClick={handleListAllAdvertisementRestaurantTags}>
                handleListAllAdvertisementRestaurantTags
              </Button>
            </Box>
          </ShowDebug>
          <Toolbar refreshAddAdvertisementTag={refreshAddAdvertisementTag} />
          <Box mb="1rem">
            <Typography variant="h4" color="textPrimary">
              {active_lang.ADVERTISEMENT_RESTAURANT_TAGS}
            </Typography>
          </Box>

          <div>
            {advertisement_restaurant_tags == null ? (
              <>{active_lang.ADVERTISEMENT_TAG_LOADING}</>
            ) : (
              <ListAdvertisementRestaurantTags
                table_body={advertisement_restaurant_tags}
                setAdvertisementRestaurantTags={setAdvertisementRestaurantTags}
                refreshAddAdvertisementTag={refreshAddAdvertisementTag}
              />
            )}
          </div>
        </Container>
      </Page>
    </>
  );
}
