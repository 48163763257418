import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import config from 'src/config';

import { markDeleteRestaurant } from 'src/modals/restaurant_config';

export default function useMutateUserDeleteRestaurantById() {
  const mutation = useMutation(restaurant_id_to_delete => {
    return markDeleteRestaurant(restaurant_id_to_delete);
  });

  return mutation;
}
