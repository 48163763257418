import { isUndefined } from 'lodash-es';
import { select_values } from 'src/modals/advertisement_config';

function getAdWindowObjByName(name_in) {
  let sanitized_ad_window = select_values.ad_window.FOOD_MENU;
  switch (name_in) {
    case select_values.ad_window.FOOD_MENU.name:
      sanitized_ad_window = select_values.ad_window.FOOD_MENU;
      break;
    case select_values.ad_window.FOOD_DETAIL.name:
      sanitized_ad_window = select_values.ad_window.FOOD_DETAIL;
      break;
    case select_values.ad_window.ORDER_LIST.name:
      sanitized_ad_window = select_values.ad_window.ORDER_LIST;
      break;
    default:
      break;
  }
  return sanitized_ad_window;
}

export default getAdWindowObjByName;
