import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import Paper from '@material-ui/core/Paper';
import GetQR from 'src/utils/GetQR';

const default_host_ip = 'localhost';

const GridQR = ({ link, text }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <Paper className={classes.paper} style={{ padding: '0.5rem' }}>
          <GetQR link={link} text={text} style={{ padding: '10px' }} />
        </Paper>
      </Grid>
    </>
  );
};

export default function MenyQRList({ restaurant_info }) {
  let { id } = restaurant_info;
  const host_to_test =
    process.env.NODE_ENV !== 'development'
      ? `https://meny-app.menymeny.com`
      : `http://${default_host_ip}:8004`;

  const restaurant_link =
    process.env.NODE_ENV !== 'development'
      ? `https://meny-manage.menymeny.com`
      : `http://${default_host_ip}:8002`;
  const admin_link =
    process.env.NODE_ENV !== 'development'
      ? `https://meny-admin.menymeny.com`
      : `http://${default_host_ip}:8003`;

  const NewUserlineup = `${host_to_test}/meny/${id}/${id}/NewUserlineup`;

  return (
    <>
      <Grid container justify="center" spacing={8}>
        <GridQR link={restaurant_link} text="restaurant link" />{' '}
        <GridQR link={admin_link} text="admin link" />{' '}
        <GridQR link={NewUserlineup} text="lineup link" />
      </Grid>
    </>
  );
}
