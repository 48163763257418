import { useMutation } from 'react-query';
import { reserveNewStadium } from 'src/modals/stadium_config';

export default function useMutateReserveNewStadiumId() {
  const mutation = useMutation('reserve-new-restaurant-id', async () => {
    return reserveNewStadium();
  });

  return mutation;
}
