import React from 'react';
import { Box } from '@material-ui/core';

import SelectAdAction from './SelectAdAction';
import SelectAdDestinationSubform from './SelectAdDestinationSubform';
import ShowDebug from '../ShowDebug';

export default function AdConfigureSlotFoodDetailSubform({
  formik,
  food_details,
}) {
  return (
    <>
      <ShowDebug>
        empty as advertistment configure slot=food detail selected
      </ShowDebug>
    </>
  );
}
