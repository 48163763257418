import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import active_lang from 'src/langs/jp_en';
import { isUndefined } from 'lodash-es';

export default function AutoCompleteRestaurantOperators({
  formik,
  list_all_user_result,
}) {
  const [
    restaurant_operators_options,
    setRestaurantOperatorsOptions,
  ] = React.useState([]);

  React.useEffect(() => {
    if (
      isUndefined(list_all_user_result.data) ||
      isUndefined(list_all_user_result.data.users)
    ) {
    } else {
      let { users } = list_all_user_result.data;
      let option_list = users.map(entry => {
        return { uid: entry.uid, email: entry.email };
      });
      console.log('AutoCompleteRestaurantOperators', 'users', users);
      setRestaurantOperatorsOptions(option_list);
    }
  }, [list_all_user_result]);

  return (
    <>
      {list_all_user_result.isLoading ? (
        <>{active_lang.LOADING_USERS}</>
      ) : (
        <>
          <Autocomplete
            id="restaurant_operators"
            {...formik.getFieldProps('restaurant_operators')}
            options={restaurant_operators_options}
            getOptionLabel={option => option.email}
            renderInput={params => (
              <TextField
                {...params}
                label="Restaurant Operator select"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) =>
              formik.setFieldValue('restaurant_operators', newValue)
            }
            disableClearable
            multiple
          />
        </>
      )}
    </>
  );
}
