import 'firebase/firestore';
import firebase from 'firebase/app';

import db_config from '../configs/db_config';

import db from '../Firebase/db';

const advertisement_tag_ref = db.collection(
  db_config.DB_TABLE_ADVERTISEMENT_TAG
);

const addNewAdvertisementTag = advertisement_tag_data => {
  let created = firebase.firestore.FieldValue.serverTimestamp();
  let sanitized_data = {
    ...advertisement_tag_data,
    created: created,
    disabled: false,
  };
  return advertisement_tag_ref.add(sanitized_data);
};

const getAllAdvertisementTag = () => {
  return advertisement_tag_ref
    .where('disabled', '==', false)
    .orderBy('created', 'desc')
    .get();
};

const disableAdvertisementTag = advertisement_tag_id => {
  let disabled_since = firebase.firestore.FieldValue.serverTimestamp();
  return advertisement_tag_ref
    .doc(advertisement_tag_id)
    .set({ disabled: true, disabled_since: disabled_since }, { merge: true });
};

const clearAdvertisementTagFromRestaurantConfig = advertisement_tag_id => {};

const saveAdvertisementTag = (advertisement_id, advertisement_tag_data) => {
  return advertisement_tag_ref
    .doc(advertisement_id)
    .set(advertisement_tag_data, { merge: true });
};

export {
  saveAdvertisementTag,
  getAllAdvertisementTag,
  addNewAdvertisementTag,
  disableAdvertisementTag,
};
