import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import active_lang from 'src/langs/jp_en';
import ListStadiums from './ListStadiums';
import Page from 'src/components/Page';
import test_stadiums from './data.js';
import Toolbar from './Toolbar';
import useQueryListAllStadiums from 'src/hooks/useQueryListAllStadiums';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function EditStadiumInfo() {
  const classes = useStyles();
  let [stadiums, setStadiums] = React.useState(test_stadiums);

  let query_list_all_stadiums = useQueryListAllStadiums();

  React.useEffect(() => {
    if (query_list_all_stadiums.isLoading) {
    } else {
      setStadiums(query_list_all_stadiums.data);
    }
  }, [query_list_all_stadiums]);

  return (
    <Page
      className={classes.root}
      title={active_lang.ADMIN_EDIT_RESTAURANT_INFO}
    >
      <Container maxWidth={false}>
        {query_list_all_stadiums.isLoading ? (
          <>{active_lang.LOADING_RESTAURANTS_AND_USERS}</>
        ) : (
          <>
            <Toolbar refetch={query_list_all_stadiums.refetch} />
            <Box mb="1rem">
              <Typography variant="h4" color="textPrimary">
                {active_lang.ADMIN_EDIT_STADIUM_INFO}
              </Typography>
            </Box>
            <Box>
              <ListStadiums
                stadiums={stadiums}
                query_list_all_stadiums={query_list_all_stadiums}
              />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
}
