const test_stadiums = [
  {
    id: '111',
    name: 'test_stadiums',
    data: {
      stadium_profile_pic: '',
      name: 'test',
      id: '111',
      status: 'active',
    },
  },
];

export default test_stadiums;
