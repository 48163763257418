import React, { useState } from 'react';
import { Typography, Divider, CardHeader, CardContent, Card, Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import step1_png from './step1.png';
import step2_png from './step2.png';
import step3_png from './step3.png';
import step4_png from './step4.png';
import step5_png from './step5.png';
import step6_png from './step6.png';

export default function AccountSegregation() {
  return (
    <>
      <Box mt="3rem" ml="3rem" mr="3rem">
        <Typography color="textPrimary" gutterBottom variant="h3">
          to create a restaurant and user
        </Typography>
        <ul>
          <li>
            <div
              style={{
                backgroundImage: `url(${step1_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>
            <div
              style={{
                backgroundImage: `url(${step2_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>
            <div
              style={{
                backgroundImage: `url(${step3_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>
            <div
              style={{
                backgroundImage: `url(${step4_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>
            <div
              style={{
                backgroundImage: `url(${step5_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>
            <div
              style={{
                backgroundImage: `url(${step6_png})`,
                width: '100%',
                height: '600px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                paddingTop: '10px',
              }}
            ></div>
          </li>
          <li>menu management</li>
        </ul>
      </Box>
    </>
  );
}
