import lookupFoodNameByFoodDetailId from './lookupFoodNameByFoodDetailId';
import lookupFoodCategoryTitleByCatId from './lookupFoodCategoryTitleByCatId';

function getAdDestination(food_details, ad_action, ad_destination) {
  console.log('ad_action', ad_action);

  let sanitized_ad_destination = {};
  switch (ad_action) {
    case 'food_menu':
      sanitized_ad_destination = {
        cat_id: ad_destination,
        cat_name: lookupFoodCategoryTitleByCatId(food_details, ad_destination),
      };
      break;
    case 'food_detail':
      console.log(
        'lookupFoodNameByFoodDetailId',
        lookupFoodNameByFoodDetailId(food_details, ad_destination)
      );
      sanitized_ad_destination = {
        food_detail_id: ad_destination,
        food_detail_name: lookupFoodNameByFoodDetailId(
          food_details,
          ad_destination
        ),
      };
      break;

    default:
      break;
  }
  return sanitized_ad_destination;
}

export default getAdDestination;
