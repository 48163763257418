import React from 'react';

import SelectMenyAdAction from './SelectMenyAdAction';
import {
  SERVICE_MENY_LIGHT,
  SERVICE_MENY,
  SERVICE_MENY_GO,
} from 'src/configs/meny_service';
import SelectMenyLightAdAction from './SelectMenyLightAdAction';
import { isUndefined, isNull } from 'lodash-es';

import active_lang from 'src/langs/jp_en';

export default function SelectAdAction({ service_class, formik }) {
  if (!isUndefined(service_class) && !isNull(service_class)) {
    switch (service_class.id) {
      case SERVICE_MENY:
        return <SelectMenyAdAction formik={formik} />;
        break;
      case SERVICE_MENY_LIGHT:
        return <SelectMenyLightAdAction formik={formik} />;
      case SERVICE_MENY_GO:
        return <SelectMenyAdAction formik={formik} />;
        break;
      default:
        return <>{JSON.stringify(service_class, null, 2)}</>;
        break;
    }
  }

  return <>{active_lang.AD_ACTION_LOADING}</>;
}
