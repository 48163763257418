import 'firebase/firestore';
import db_config from '../configs/db_config';
import db from '../Firebase/db';

const STADIUM_REF = db.collection(db_config.DB_TABLE_STADIUM_CONFIG);
const STADIUM_URL_REF = db.collection(db_config.DB_STADIUM_URL);

// Obsolete
function updateStadiumConfig(id, payload) {
  return STADIUM_REF.doc(id).set({ ...payload }, { merge: true });
}

function updateStadiumConfigByRestId(id, payload) {
  return STADIUM_REF.doc(id).set({ ...payload }, { merge: true });
}

function listAllStadiums() {
  return STADIUM_REF.where('status', '==', 'active')
    .get()
    .then(docs => {
      let a_d = [];
      docs.forEach(doc => {
        a_d.push({ id: doc.id, data: doc.data() });
      });
      return a_d;
    });
}

function subscribeAllStadiums() {
  return STADIUM_REF.where('status', '==', 'active');
}

function listMarkDeleteStadiums() {
  return STADIUM_REF.where('status', '==', 'deleted')
    .get()
    .then(docs => {
      let a_d = [];
      docs.forEach(doc => a_d.push({ id: doc.id, data: doc.data() }));
      return a_d;
    });
}

function addStadiums(config_to_add) {
  return STADIUM_REF.add(config_to_add);
}

function reserveNewStadium() {
  return STADIUM_REF.doc().id;
}

function markDeleteStadium(stadium_id_to_delete) {
  return STADIUM_REF.doc(stadium_id_to_delete).set(
    { status: 'deleted' },
    { merge: true }
  );
}

function deleteStadium(stadium_id_to_delete) {
  return STADIUM_REF.doc(stadium_id_to_delete).delete();
}

function getStadiumUrl() {
  return STADIUM_URL_REF.doc(db_config.DB_STADIUM_URL).get();
}

export {
  updateStadiumConfig,
  updateStadiumConfigByRestId,
  listAllStadiums,
  subscribeAllStadiums,
  listMarkDeleteStadiums,
  addStadiums,
  reserveNewStadium,
  markDeleteStadium,
  deleteStadium,
  getStadiumUrl,
};
