import React from 'react';
import { useQuery } from 'react-query';
import config from 'src/config';
import { listAllStadiums } from 'src/modals/stadium_config';

const useQueryListAllStadiums = () => {
  return useQuery(
    ['list-all-stadiums'],
    () => {
      return listAllStadiums().then(a_d => {
        let options = [];
        a_d
          .sort((a, b) => a.data.name - b.data.name)
          .forEach(d =>
            options.push({ name: d.data.name, id: d.id, data: d.data })
          );
        return options;
      });
    },
    // disable to avoid loading all image on every window focus in edit_stadium_info form
    { refetchOnWindowFocus: false }
  );
};

export default useQueryListAllStadiums;
