import React from 'react';
import QRCode from 'react-qr-code';
import { Box, Typography } from '@material-ui/core';

export default function GetQR({ link, text }) {
  return (
    <>
      <Box style_del={{ marginTop: '5rem', marginBottom: '5rem' }}>
        <Box style_del={{ marginBottom: '1rem' }}></Box>
      </Box>

      <Box
        style_del={{
          marginTop: '5rem',
          marginBottom: '5rem',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box></Box>
        <Box>
          <a href={link} target="_blank">
            <Box
              style_del={{
                border: '1px',
                borderStyle: 'dashed',
                padding: '4rem',
              }}
            >
              <Box>
                <Typography variant="caption" color="textPrimary">
                  {text}
                </Typography>
              </Box>

              <Box>
                <QRCode
                  bgColor="#FFFFFF"
                  level="Q"
                  style={{ width: '100%', height: 'auto' }}
                  value={link}
                />
              </Box>
              <Typography variant="caption" color="textPrimary">
                {text}
              </Typography>
            </Box>
          </a>
        </Box>
        <Box></Box>
      </Box>
    </>
  );
}
