import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  IconButton,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import { isUndefined, isNull } from 'lodash-es';
import ShowDebug from 'src/components/ShowDebug';

import EditAdvertisementDialog from 'src/components/EditAdvertisementDialog';
import ConfirmDeleteAdvertisementDialog from 'src/components/ConfirmDeleteAdvertisementDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import getDataById from 'src/utils/getDataById';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function processShowColAdAction(data) {
  if (isUndefined(data.ad_window)) {
    return undefined;
  } else {
    if (data.ad_window.name == 'food_menu') {
      return data.ad_destination;
    }
  }
  return undefined;
}

const lookupRestaurantFoodDetailsByRestId = (all_food_details, rest_id) => {
  let found = false;
  let found_food_detail = {};
  for (let i = 0; i < all_food_details.length; i++) {
    console.log('test', 'food_detail.id', all_food_details[i]);
    console.log('test', 'rest_id', rest_id);
    if (all_food_details[i].id == rest_id) {
      found_food_detail = all_food_details[i];
      found = true;
    }
  }
  if (found) {
    return found_food_detail;
  } else {
    return undefined;
  }
};

const lookupRestaurantNameByRestId = (restaurant_result_list, rest_id) => {
  let found = false;
  let found_restaurant = {};
  for (let i = 0; i < restaurant_result_list.length; i++) {
    console.log(
      'lookupRestaurantNameByRestId',
      'restaurant_result_list.id',
      restaurant_result_list[i].id
    );
    if (restaurant_result_list[i].id == rest_id) {
      console.log('lookupRestaurantNameByRestId', 'helloworld');
      found_restaurant = restaurant_result_list[i];
      found = true;
    } else {
      console.log('lookupRestaurantNameByRestId', 'not match');
    }
  }

  if (found) {
    return found_restaurant.data.name;
  } else {
    return 'restaurant not found';
  }
};

function processShowColAdDestination({ rest_id, all_food_details, data }) {
  if (
    isUndefined(rest_id) ||
    isNull(rest_id) ||
    isUndefined(data.ad_destination) ||
    rest_id == '-'
  ) {
    return '-';
  } else {
    if (data.ad_action.name == 'food_detail') {
      return data.ad_destination.food_detail_name;
    }
    if (data.ad_action.name == 'food_menu') {
      return data.ad_destination.cat_name;
    }
  }
  return '-';
}

function Actions({
  formik,
  ad_info,
  all_restaurants_result,
  all_food_details_result,
  all_advertisement_result,
  refetch,
}) {
  let [
    open_edit_advertisement_dialog,
    setOpenEditAdvertisementDialog,
  ] = React.useState(false);
  let [
    open_delete_confirm_dialog,
    setOpenConfirmDeleteAdvertisementDialog,
  ] = React.useState(false);

  const handleDeleteClick = () => {
    setOpenConfirmDeleteAdvertisementDialog(true);
  };

  return (
    <>
      <EditAdvertisementDialog
        open={open_edit_advertisement_dialog}
        setOpen={setOpenEditAdvertisementDialog}
        ad_info={ad_info}
        all_restaurants_result={all_restaurants_result}
        all_food_details_result={all_food_details_result}
        all_advertisement_result={all_advertisement_result}
        refetch={refetch}
      />
      <ConfirmDeleteAdvertisementDialog
        open={open_delete_confirm_dialog}
        setOpen={setOpenConfirmDeleteAdvertisementDialog}
        advertisement_to_delete={ad_info}
        refetch={refetch}
      />
      <IconButton onClick={() => setOpenEditAdvertisementDialog(true)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleDeleteClick()}>
        <DeleteForeverIcon />
      </IconButton>
    </>
  );
}

const lookupAdActionName = ad_action_name => {
  switch (ad_action_name) {
    case 'order_list':
      return active_lang.AD_ORDER_LIST;
    case 'food_menu':
      return active_lang.AD_ACTION_GO_TO_FOOD_MENU;
    case 'food_detail':
      return active_lang.AD_ACTION_GO_TO_FOOD_DETAIL;

    default:
      return active_lang.AD_ACTION_GO_TO_FOOD_DETAIL;
  }
};

const lookupAdWindowName = ad_window_name => {
  switch (ad_window_name) {
    case 'order_list':
      return active_lang.AD_ORDER_LIST;
    case 'food_menu':
      return active_lang.AD_FOOD_MENU;
    case 'food_detail':
      return active_lang.AD_FOOD_DETAIL;

    default:
      return active_lang.AD_FOOD_DETAIL;
  }
};

export default function ListAdvertisements({
  className,
  advertisement_infos,
  all_food_details_result,
  all_restaurants_result,
  all_advertisement_result,
  ...rest
}) {
  const classes = useStyles();

  let [show_advertisement_list, setShowAdvertisementList] = React.useState([]);

  React.useEffect(() => {
    console.log('advertisement_infos', advertisement_infos);
    if (
      isUndefined(all_food_details_result) ||
      isUndefined(all_food_details_result.data)
    ) {
    } else {
      setShowAdvertisementList(
        advertisement_infos.map(({ id, data }, idx) => {
          let temp = {
            id,
            data: {
              ...data,
              show_col_ad_destination: processShowColAdDestination({
                rest_id: data.rest_id,
                all_food_details: all_food_details_result.data,
                data,
              }),
              show_col_ad_action: processShowColAdAction(data),
            },
          };
          return temp;
        })
      );
    }
  }, [advertisement_infos, all_food_details_result]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}></Box>
        </PerfectScrollbar>
        {/* <pre>{JSON.stringify(all_food_details_result.data, null, 2)}</pre> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '100px' }}>
                {active_lang.COL_TITLE}
              </TableCell>
              <TableCell>{active_lang.COL_IMAGE}</TableCell>
              <TableCell>{active_lang.COL_RESTAURANT_NAME}</TableCell>
              <TableCell>{active_lang.COL_AD_CONFIGURE_SLOT}</TableCell>
              <TableCell>{active_lang.COL_AD_ACTION}</TableCell>
              <TableCell>{active_lang.COL_AD_DESTINATION}</TableCell>
              <TableCell>{active_lang.COL_AD_EDIT}</TableCell>

              {/* <TableCell>{'debug'}</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {show_advertisement_list == [] ? (
              <></>
            ) : (
              <>
                {show_advertisement_list.map((ad_info, idx) => {
                  let ad_info_data = ad_info.data;

                  console.log('ad_info.data', ad_info.data);

                  if (
                    isUndefined(ad_info.data.rest_id) ||
                    isUndefined(ad_info.data.ad_action) ||
                    ad_info.data.rest_id == '-' ||
                    isUndefined(
                      getDataById(
                        all_food_details_result.data,
                        ad_info.data.rest_id
                      )
                    ) ||
                    isUndefined(
                      getDataById(
                        all_restaurants_result.data,
                        ad_info.data.rest_id
                      )
                    )
                  ) {
                    console.log(`${ad_info.id}`, 'record invalid');
                    return <></>;
                  }
                  return (
                    <>
                      <TableRow name={ad_info.id} hover key={idx}>
                        <TableCell
                          style={{ width: '100px', lineBreak: 'anywhere' }}
                        >
                          {ad_info_data.title}
                        </TableCell>
                        <TableCell>
                          <Box
                            style={{
                              width: '100px',
                              height: '100px',
                              backgroundImage: `url('${
                                isUndefined(ad_info_data.image_urls) ||
                                ad_info_data.image_urls[0] == ''
                                  ? '-- no image --'
                                  : ad_info_data.image_urls[0]
                              }')`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                          ></Box>
                        </TableCell>
                        <TableCell>
                          {isUndefined(ad_info_data.ad_window) ? (
                            <>-</>
                          ) : (
                            <>
                              {/* <>{JSON.stringify(ad_info_data)}</> */}
                              <>
                                {lookupRestaurantNameByRestId(
                                  all_restaurants_result.data,
                                  ad_info_data.rest_id
                                )}
                              </>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {isUndefined(ad_info_data.ad_window) ? (
                            <>-</>
                          ) : (
                            <>
                              {lookupAdWindowName(ad_info_data.ad_window.name)}
                              <ShowDebug>
                                {ad_info_data.ad_window.name}
                              </ShowDebug>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {isUndefined(ad_info_data.ad_action) ||
                          ['order_list', 'food_detail'].includes(
                            ad_info_data.ad_window.name
                          ) ? (
                            <>-</>
                          ) : (
                            <>
                              {lookupAdActionName(ad_info_data.ad_action.name)}
                              <ShowDebug>
                                {ad_info_data.ad_action.name}
                              </ShowDebug>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {isUndefined(ad_info_data.show_col_ad_destination) ||
                          ['order_list', 'food_detail'].includes(
                            ad_info_data.show_col_ad_destination
                          ) ? (
                            <>-</>
                          ) : (
                            <>{ad_info_data.show_col_ad_destination}</>
                          )}
                        </TableCell>
                        <TableCell>
                          <Actions
                            ad_info={ad_info}
                            all_restaurants_result={all_restaurants_result}
                            all_food_details_result={all_food_details_result}
                            all_advertisement_result={all_advertisement_result}
                            refetch={all_advertisement_result.refetch}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </Card>
    </>
  );
}
