import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/storage';

import firebase_app from 'src/Firebase/config';

const TEST_HOST = 'localhost';
const db = firebase_app.firestore();

if (process.env.NODE_ENV === 'development') {
  db.useEmulator(TEST_HOST, 8080);
  firebase.auth().useEmulator(`http://${TEST_HOST}:9099`);
  firebase.storage().useEmulator(TEST_HOST, 9199);
}

export default db;
