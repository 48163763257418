import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, makeStyles } from '@material-ui/core';
import { UserPlus } from 'react-feather';
import active_lang from 'src/langs/jp_en';
import AddNewStadiumDialog from 'src/components/AddNewStadiumDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, refetch, ...rest }) => {
  const classes = useStyles();
  let [open_new_stadium_dialog, setOpenNewStadiumDialog] = React.useState(false);

  return (
    <>
      <AddNewStadiumDialog
        open={open_new_stadium_dialog}
        setOpen={setOpenNewStadiumDialog}
        refetch={refetch}
      />
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <div style={{ display: 'none' }}>
            <Button className={classes.importButton}>Import</Button>
            <Button className={classes.exportButton}>Export</Button>
          </div>
          <Button
            id="admin-add-new-restaurant"
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={() => {
              setOpenNewStadiumDialog(true);
            }}
          >
            {active_lang.ADMIN_ADD_NEW_STADIUM}
          </Button>
        </Box>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
