const stadium_initial_value = {
  name: '',
  stadium_profile_pic: '',
  status: 'active',
  max_drink_quantity: 6,
  max_food_quantity: 10,
  enable_drink_quantity_control: false,
  enable_food_quantity_control: false,
};

export { stadium_initial_value };
