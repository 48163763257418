import React from 'react';
import { useFormik } from 'formik';

import ImageHandler from './ImageHandler';
import { FormControl } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import SelectRestaurant from './SelectRestaurant';
import isBase64Image from 'src/utils/isBase64Image';

import active_lang from 'src/langs/jp_en';

import { useStyles, Transition } from './styles';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import EnqueueAlert from 'src/contexts/EnqueueAlertContext';
import FormAdConfig from './FormAdConfig';
import ShowDebugJson from 'src/components/ShowDebugJson';
import getDataById from 'src/utils/getDataById';
import useMutateNewAdvertisementId from 'src/hooks/useMutateNewAdvertisementId';
import useMutateAddAdvertisement from 'src/hooks/useMutateAddAdvertisement';

import getAdWindowObjByName from 'src/utils/getAdWindowObjByName';
import getAdActionObjByName from 'src/utils/getAdActionObjByName';
import getAdDestination from 'src/utils/getAdDestination';
import getServiceClassById from 'src/utils/getServiceClassById';
import { isNull, isUndefined } from 'lodash-es';

const {
  MENY_SERVICE_MENY,
  MENY_SERVICE_MENY_LIGHT,
} = require('src/modals/restaurant_config');

const LOG_PREFIX = 'AddAdvertisementDialog';

export default function AddAdvertisementDialog({
  open,
  setOpen,
  ad_info,
  all_restaurants_result,
  all_food_details_result,
  all_advertisement_result,
  refetch,
}) {
  let classes = useStyles();
  let { showEnqueue } = React.useContext(EnqueueAlert);

  let mutationNewAdvertisementId = useMutateNewAdvertisementId();
  let mutationAddAdvertisement = useMutateAddAdvertisement();
  let mutateFirestoreUpload = useFirestoreUpload();

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ad_type: 'AD_TYPE_LYNKED_BOTTOM_ADVERTISEMENT',
      image_urls: ['', '', ''],
      rest_id: 'please_select',
      title: '',
      is_enabled: true,

      form_ad_window: undefined,
      form_ad_action: 'food_menu',
      form_ad_destination: 'please_select',

      // abonded
      target: {
        restaurant: 'target_restaurant',
        link: 'target_link',
        category: 'target_category',
      },
      restaurant_service_tags: 'restaurant_service_tags',
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        active_lang.ADVERTISEMENT_NAME_IS_MISSING_ERROR
      ),
      rest_id: Yup.mixed().notOneOf(
        ['please_select'],
        active_lang.AD_INVALID_REST_SELECTED
      ),
      image_urls: Yup.array()
        .test(
          'ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY',
          active_lang.ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY,
          value => {
            // console.log('image_urls validate', value);
            if (value.length != 3) return false;
            return true;
          }
        )
        .test(
          'not a valid image',
          active_lang.ADVERTISEMENT_IMAGE_CANNOT_BE_EMPTY,
          value => {
            if (
              /^data:.+/.test(value[0]) ||
              /^http:.+/.test(value[0]) ||
              /^https:.+/.test(value[0])
            )
              return true;
            return false;
          }
        ),
      form_ad_destination: Yup.mixed().notOneOf(
        ['please_select'],
        'invalid_ad_destination'
      ),
    }),
    onSubmit: values => {
      showEnqueue.warning(active_lang.AD_ADVERTISEMENT_UPDATE_IN_PROGRESS);
      let rest_id = formik.values.rest_id;
      let food_details = getDataById(all_food_details_result.data, rest_id)
        .data;

      let sanitized_ad_window = getAdWindowObjByName(values.form_ad_window);
      console.log('sanitized_ad_window', sanitized_ad_window);

      let sanitized_ad_action = {
        ...values.ad_action,
        ...getAdActionObjByName(values.form_ad_action),
      };
      console.log(
        'sanitized_ad_action',
        sanitized_ad_action,
        values.form_ad_action
      );

      let sanitized_ad_destination = getAdDestination(
        food_details,
        values.form_ad_action,
        values.form_ad_destination
      );
      console.log('sanitized_ad_destination', sanitized_ad_destination);

      let sanitized_value = {
        is_enabled: true,
        image_urls: values.image_urls,
        title: values.title,
        target: values.target,
        ad_type: values.ad_type,
        restaurant_service_tags: values.restaurant_service_tags,
        ad_action: sanitized_ad_action,
        ad_window: sanitized_ad_window,
        ad_destination: sanitized_ad_destination,
      };
      console.log('sanitized_value', sanitized_value);

      mutationNewAdvertisementId
        .mutateAsync()
        .then(async new_advertisement_id => {
          let file_path = `rest_advertisement/${rest_id}/${new_advertisement_id}/image_0`;

          console.log(LOG_PREFIX, {
            file_path,
            rest_id,
            new_advertisement_id,
          });

          let image_base64 = sanitized_value.image_urls[0];

          if (isBase64Image(image_base64)) {
            console.log(LOG_PREFIX, 'update Advertisement with image');

            return await mutateFirestoreUpload
              .mutateAsync({ file_path, file_base64: image_base64 })
              .then(data => {
                return mutationAddAdvertisement.mutateAsync({
                  payload: {
                    ...sanitized_value,
                    rest_id: rest_id,
                    image_urls: [data.download_url, '', ''],
                  },
                });
              })
              .then(() => {
                showEnqueue.success(
                  active_lang.AD_ADVERTISEMENT_UPDATE_SUCCESS
                );

                refetch();
                formik.resetForm();
              })
              .then(() => handleClose())
              .catch(err => {
                showEnqueue.error(
                  active_lang.SORRY_BUT_I_CANNOT_UPDATE_ADVERTISEMENT_DATA
                );
                console.error(LOG_PREFIX, { err });
              });
          }
        })
        .catch(err => {
          console.error(LOG_PREFIX, { err });
        })
        .finally(() => {
          formik.resetForm();
          formik.setSubmitting(false);
        });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const getAdvertisementByRestId = (advertisement_list, rest_id) => {
    return advertisement_list.filter(a => a.data.rest_id == rest_id);
  };

  React.useEffect(() => {
    if (
      isUndefined(formik.values.rest_id) ||
      isNull(formik.values.rest_id) ||
      formik.values.rest_id == 'please_select'
    ) {
    } else {
      let { rest_id } = formik.values;
      let rest_advertisement_list = getAdvertisementByRestId(
        all_advertisement_result.data,
        rest_id
      );
      let service_class = getServiceClassById(
        all_restaurants_result.data,
        rest_id
      );

      console.log(LOG_PREFIX, {
        rest_id,
        service_class,
        MENY_SERVICE_MENY,
        MENY_SERVICE_MENY_LIGHT,
      });

      if (service_class.id == MENY_SERVICE_MENY.id) {
        switch (rest_advertisement_list.length) {
          case 1:
            if (rest_advertisement_list[0].data.ad_window.name == 'food_menu') {
              formik.setFieldValue('form_ad_window', 'order_list');
              formik.setFieldValue('form_ad_destination', 'order_list');
            } else if (
              rest_advertisement_list[0].data.ad_window.name == 'order_list'
            ) {
              formik.setFieldValue('form_ad_window', 'food_menu');
              formik.setFieldValue('form_ad_destination', 'please_select');
            } else {
            }
            break;
          default:
            break;
        }
      } else if (service_class.id == MENY_SERVICE_MENY_LIGHT.id) {
        switch (rest_advertisement_list.length) {
          case 1:
            if (rest_advertisement_list[0].data.ad_window.name == 'food_menu') {
              formik.setFieldValue('form_ad_window', 'food_detail');
              formik.setFieldValue('form_ad_destination', 'food_detail');
            } else if (
              rest_advertisement_list[0].data.ad_window.name == 'food_detail'
            ) {
              // alert('helloworld');
              formik.setFieldValue('form_ad_window', 'food_menu');
              formik.setFieldValue('form_ad_destination', 'please_select');
            } else {
            }
            break;
          default:
            break;
        }
      } else {
        console.log(LOG_PREFIX, 'service_class not found');
      }
    }
  }, [formik.values.rest_id]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <button onClick={testSetFieldValue}>testSetFieldValue</button> */}
        <DialogTitle id="alert-dialog-slide-title">
          {active_lang.AD_ADD_NEW_ADS}
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          <Box mt="1rem">
            <FormControl fullWidth>
              <Box>{active_lang.AD_NAME}</Box>
              <TextField
                id="title"
                {...formik.getFieldProps('title')}
                className={classes.TextInput}
                placeholder={active_lang.AD_NAME}
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: { padding: '1rem' } }}
                style={{ marginTop: '0.5rem' }}
                fullWidth
              />

              {formik.touched.title && formik.errors.title ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{formik.errors.title}</Box>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          <Box mt={'0.5rem'}>
            <FormControl fullWidth>
              <Box>{active_lang.AD_IMAGE}</Box>
              <ImageHandler
                id="image_urls"
                {...formik.getFieldProps('image_urls')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                image_url={
                  formik.values.image_urls && formik.values.image_urls[0]
                }
                edit_show_detail={formik.values}
                setEditShowDetail={`setEditShowDetail`}
              />
              {formik.touched.image_urls && formik.errors.image_urls ? (
                <Box className={classes.user_input_error}>
                  <Box pr="1rem">
                    <WarningIcon fontSize="small" />
                  </Box>
                  <Box>{formik.errors.image_urls}</Box>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          <SelectRestaurant
            formik={formik}
            all_restaurants_result={all_restaurants_result}
            all_advertisement_result={all_advertisement_result}
          />
          <FormAdConfig
            service_class={getServiceClassById(
              all_restaurants_result.data,
              formik.values.rest_id
            )}
            formik={formik}
            food_details={getDataById(
              all_food_details_result.data,
              formik.values.rest_id
            )}
            all_restaurants_result={all_restaurants_result}
            all_advertisement_result={all_advertisement_result}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            type="button"
            color="primary"
          >
            {active_lang.AD_EDIT_CANCEL}
          </Button>
          <Button
            disabled={formik.isSubmitting}
            type={'button'}
            color="primary"
            variant="contained"
            onClick={e => {
              formik.handleSubmit(e);
            }}
          >
            {active_lang.AD_EDIT_CONFIRM}
          </Button>
        </DialogActions>
        <ShowDebugJson>{JSON.stringify(formik.errors)}</ShowDebugJson>
        <ShowDebugJson>{JSON.stringify(formik.values)}</ShowDebugJson>
        <ShowDebugJson>{JSON.stringify(ad_info)}</ShowDebugJson>
      </Dialog>
    </>
  );
}
