import React from 'react';

import {
  Box,
  Button,
  IconButton,
  InputBase,
  makeStyles,
  Slide,
  withStyles,
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import {
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

import testImageUrlValid from './testImageUrlValid';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: { color: LYNKED_RED },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    border: `1px dashed #263238`,
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
}));

export default function ImageHandler({
  image_url,
  setEditShowDetail,
  edit_show_detail,
  onChange,
  onBlur,
  ...rest
}) {
  const classes = useStyles();
  const file_ref = React.useRef(null);
  const [process_status, setProcessStatus] = React.useState('');

  const [add_image_button_disabled, setAddImageButtonDisabled] = React.useState(
    false
  );

  const handleClearImageButtonClick = () => {
    let dummy_e = {
      target: {
        id: 'image_urls',
        value: [],
      },
    };
    onBlur(dummy_e);
    onChange(dummy_e);
  };

  const handleFileInput = () => {
    setProcessStatus(active_lang.IN_PROGRESS);
    setAddImageButtonDisabled(true);
    let selectedFile = file_ref.current.files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      // handleUpdatePreview(e.target.result);
      let dummy_e = {
        target: {
          id: 'image_urls',
          value: [e.target.result, '', ''],
        },
      };
      onChange(dummy_e);
      onBlur(dummy_e);

      setAddImageButtonDisabled(false);
      setProcessStatus('');
    };
    reader.readAsDataURL(selectedFile);
  };

  const [show_image, setShowImage] = React.useState(false);
  React.useEffect(() => {
    setShowImage(testImageUrlValid(image_url));
  }, [image_url]);

  return (
    <>
      {show_image ? (
        <>
          <div
            style={{
              width: '100%',
              height: '300px',

              backgroundImage: `url(${image_url})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                left: '25%',
              }}
            >
              <IconButton
                onClick={handleClearImageButtonClick}
                classes={{ root: classes.test_icon_style }}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <>
          <input
            type="file"
            onChange={handleFileInput}
            ref={file_ref}
            accept=".png,.bmp,.jpg"
            style={{ display: 'none' }}
          />
          <Box
            className={classes.AddImagePlaceHolder}
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',

              color: LYNKED_RED,
            }}
          >
            <Box>
              <Button
                disabled={add_image_button_disabled}
                onClick={() => {
                  file_ref.current.click();
                }}
                className={classes.AddImageButton}
                startIcon={<AddAPhotoIcon />}
                style={{ textAlign: 'left' }}
              >
                {active_lang.ADD_IMAGE}
                <br />
                {active_lang.ADD_IMAGE_APPENDIX}
              </Button>
            </Box>
            <Box>{process_status}</Box>
          </Box>
        </>
      )}
    </>
  );
}
