import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {
  styled,
  Box,
  TextField,
  Button,
  makeStyles,
  Input,
} from '@material-ui/core';

import {
  LYNKED_DEEP_GREY,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_RED,
  LYNKED_WHITE,
} from 'src/consts/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  field_error_highlight: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
