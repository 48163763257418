import { useMutation } from 'react-query';
import { updateRestaurantConfigByRestId } from 'src/modals/restaurant_config';

export default function useUpdateRestaurantInfo() {
  const mutation = useMutation(({ rest_id, payload, fieldsToDelete = [] }) => {
    return updateRestaurantConfigByRestId(rest_id, payload, fieldsToDelete);
  });

  return mutation;
}
