import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import SportsCricket from '@material-ui/icons/SportsCricket';
import PictureInPictureAltOutlinedIcon from '@material-ui/icons/PictureInPictureAltOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';

import { BarChart as BarChartIcon } from 'react-feather';

import active_lang from 'src/langs/jp_en';

const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const production_items = [
  {
    href: `${ENV_PUBLIC_URL}/app/dashboard`,
    icon: BarChartIcon,
    title: active_lang.OVERVIEW,
    id: 'nav-dashboard',
  },

  {
    href: `${ENV_PUBLIC_URL}/app/edit_user_info`,
    icon: FaceOutlinedIcon,
    title: 'レストランスタッフ',
    id: 'nav-user-info',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/edit_restaurant_info`,
    icon: StorefrontOutlinedIcon,
    title: 'レストラン',
    id: 'nav-restaurant-info',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/edit_advertisement_info`,
    icon: PictureInPictureAltOutlinedIcon,
    title: '広告',
    id: 'nav-advertisement-info',
  },
  {
    href: `${ENV_PUBLIC_URL}/app/edit_stadium_info`,
    icon: SportsCricket,
    title: 'スタジアム',
    id: 'nav-stadium-info',
  },
  {
    href: `${ENV_PUBLIC_URL}/logout`,
    icon: ExitToAppIcon,
    title: 'logout',
  },
];

export default production_items;
