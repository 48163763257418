import React from 'react';

import AdConfigureSlotFoodMenuSubform from './AdConfigureSlotFoodMenuSubform';
import AdConfigureSlotOrderListSubform from './AdConfigureSlotOrderListSubform';
import AdConfigureSlotFoodDetailSubform from './AdConfigureSlotFoodDetailSubform';
import ShowDebug from '../ShowDebug';

export default function AdConfigureSlotSubform({ formik, food_details }) {
  switch (formik.values.form_ad_window) {
    case 'food_menu':
      return (
        <>
          <ShowDebug>AdConfigureSlotFoodMenuSubform</ShowDebug>
          <AdConfigureSlotFoodMenuSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    case 'order_list':
      return (
        <>
          <ShowDebug>AdConfigureSlotOrderListSubform</ShowDebug>
          <AdConfigureSlotOrderListSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    case 'food_detail':
      return (
        <>
          <ShowDebug>AdConfigureSlotFoodDetailSubform</ShowDebug>
          <AdConfigureSlotFoodDetailSubform
            formik={formik}
            food_details={food_details}
          />
        </>
      );

    default:
      return <></>;
  }
}
