import React from 'react';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

import SelectRestaurant from './SelectRestaurant';
// import ShowDebug from 'src/components/ShowDebug';

import active_lang from 'src/langs/jp_en';

import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

import useMutateCopyFoodMenu from 'src/hooks/useMutateCopyFoodMenu';
import ShowDebug from 'src/components/ShowDebug';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CopyFoodmenuDialog({
  open,
  setOpen,
  dst_rest_id,
  all_restaurants_result,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let mutate_copy_foodmenu = useMutateCopyFoodMenu();

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: { src_rest_id: 'please_select', dst_rest_id },
    onSubmit: values => {
      console.log('mutate_copy_foodmenu', 'values', values);
      mutate_copy_foodmenu
        .mutateAsync({
          src_rest_id: values.src_rest_id,
          dst_rest_id: values.dst_rest_id,
        })
        .then(() => {
          enqueueSnackbar('copy food menu done', {
            variant: 'success',
          });
        })
        .then(() => {
          setOpen(false);
        })
        .catch(err => {
          enqueueSnackbar('error during copy food menu', {
            variant: 'error',
          });
          console.error('mutate_copy_foodmenu', err);
        });
    },
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth={'lg'}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>from which restaurant to this restaurant ?</Box>
              <SelectRestaurant
                formik={formik}
                all_restaurants_result={all_restaurants_result}
                rest_id_to_skip={dst_rest_id}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogActions>
          <Button
            type="reset"
            disabled={false}
            onClick={handleClose}
            color="primary"
          >
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
          <Button
            type="submit"
            disabled={false}
            variant={'contained'}
            style={{ backgroundColor: LYNKED_RED, color: LYNKED_WHITE }}
          >
            OK, copy
          </Button>
        </DialogActions>
        <ShowDebug>{JSON.stringify(formik.values, null, 2)}</ShowDebug>
      </form>
    </Dialog>
  );
}
