let ADMIN_EDIT_RESTAURANT_INFO = 'レストランを編集する';
let ADMIN_EDIT_STADIUM_INFO = 'スタジアム編集';
let ENABLE_AD_CONFIG_ON_CMS = 'ENABLED AD CONFIG ON CMS';
let ENABLE_MENU_QUANTITY_CONTROL = 'ENABLE MENU QUANTITY CONTROL';
let FOOD = 'FOOD';
let MAX_FOOD_QUANTITY = 'MAX_FOOD_QUANTITY ';
let DRINK = 'DRINK';
let MAX_DRINK_QUANTITY = 'MAX_DRINK_QUANTITY ';
let ENABLE_SALES_COUNT = 'ENABLED SALES COUNT';
let RESTAURANT_ACTIVE = 'RESTAURANT ACTIVE';
let ADMIN_ADD_NEW_RESTAURANT = '新しいレストラン';

let RESTAURANT_NAME_IS_REQUIRED = 'RESTAURANT_NAME_IS_REQUIRED';
let AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED = 'AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED';
let ADMIN_RESTAURANT_NAME = 'レストラン';
let ADMIN_RESTAURANT_SERVICE_NAME = 'サービス';

let TRY_CREATE_RESTAURANT = 'try create restaurant';
let INITIALIZE_NEW_RESTAURANT = 'initialize new restaurant';
let CREATE_RESTAURANT_DONE = 'create restaurant done';
let ERROR_DURING_CREATE_RESTAURANT = 'error during create restaurant';

let LOADING_USERS = 'loading users';

let LOADING_RESTAURANTS_AND_USERS = 'loading restaurants and users';
let UPDATING_RESTAURANTS_AND_USERS = 'updating restaurants and users';
let STADIUM_ADDRESS = 'Address';
let STADIUM_BUSINESS_NAME = 'Business name';
let STADIUM_REGISTRATION_CODE = 'Registration code';
let STADIUM_PHONE = 'Phone';

export default {
  RESTAURANT_ACTIVE,
  UPDATING_RESTAURANTS_AND_USERS,
  LOADING_RESTAURANTS_AND_USERS,
  LOADING_USERS,
  ERROR_DURING_CREATE_RESTAURANT,
  CREATE_RESTAURANT_DONE,
  ADMIN_RESTAURANT_NAME,
  ADMIN_EDIT_RESTAURANT_INFO,
  ADMIN_EDIT_STADIUM_INFO,
  ENABLE_AD_CONFIG_ON_CMS,
  ENABLE_MENU_QUANTITY_CONTROL,
  FOOD,
  DRINK,
  MAX_FOOD_QUANTITY,
  MAX_DRINK_QUANTITY,
  ADMIN_ADD_NEW_RESTAURANT,
  RESTAURANT_NAME_IS_REQUIRED,
  AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED,
  TRY_CREATE_RESTAURANT,
  INITIALIZE_NEW_RESTAURANT,
  ENABLE_SALES_COUNT,
  ADMIN_RESTAURANT_SERVICE_NAME,
  STADIUM_ADDRESS,
  STADIUM_BUSINESS_NAME,
  STADIUM_REGISTRATION_CODE,
  STADIUM_PHONE,
};
