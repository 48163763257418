import { useMutation } from 'react-query';
import { updateStadiumConfigByRestId } from 'src/modals/stadium_config';

export default function useUpdateStadiumInfo() {
  const mutation = useMutation(({ rest_id, payload }) => {
    return updateStadiumConfigByRestId(rest_id, payload);
  });

  return mutation;
}
