import 'firebase/firestore';
import db_config from '../configs/db_config';
import db from '../Firebase/db';

const LOG_PREFIX = 'restaurant_config';

const RESTAURANT_REF = db.collection(db_config.DB_TABLE_RESTAURANT_CONFIG);

const MENY_SERVICE_MENY_LIGHT = { name: 'meny_light(4)', id: 4 };
// const MENY_SERVICE_MENY_IN = { name: 'meny in(8)', id: 8 };
const MENY_SERVICE_MENY_GO = { name: 'meny go(16)', id: 16 };
const MENY_SERVICE_MENY = { name: 'meny(32)', id: 32 };

const service_category = [
  MENY_SERVICE_MENY_LIGHT,
  // MENY_SERVICE_MENY_IN,
  MENY_SERVICE_MENY_GO,
  MENY_SERVICE_MENY,
];

const select_values = {
  status: { active: 'active', disabled: 'disabled' },
  meny_service_class: {
    MENY_SERVICE_MENY_LIGHT,
    // MENY_SERVICE_MENY_IN,
    MENY_SERVICE_MENY_GO,
    MENY_SERVICE_MENY,
  },
};

// Obsolete
function updateRestaurantConfig(id, payload) {
  return db
    .collection(db_config.DB_TABLE_RESTAURANT_CONFIG)
    .doc(id)
    .set({ ...payload }, { merge: true });
}

function updateRestaurantConfigByRestId(id, payload, fieldsToDelete = []) {
  const updatePayload = { ...payload };
  fieldsToDelete.forEach(field => {
    delete updatePayload[field];
  });

  return RESTAURANT_REF.doc(id).set(updatePayload);
}

function listAllRestaurants() {
  return RESTAURANT_REF.where('status', '==', 'active')
    .get()
    .then(docs => {
      let a_d = [];
      docs.forEach(doc => {
        a_d.push({ id: doc.id, data: doc.data() });
      });
      return a_d;
    });
}

function subscribeAllRestaurants() {
  return RESTAURANT_REF.where('status', '==', 'active');
}

function listMarkDeleteRestaurants() {
  return RESTAURANT_REF.where('status', '==', 'deleted')
    .get()
    .then(docs => {
      let a_d = [];
      docs.forEach(doc => a_d.push({ id: doc.id, data: doc.data() }));
      return a_d;
    });
}

function addRestaurants(config_to_add) {
  return RESTAURANT_REF.add(config_to_add);
}

function reserveNewRestaurant() {
  return RESTAURANT_REF.doc().id;
}

function markDeleteRestaurant(restaurant_id_to_delete) {
  console.log(LOG_PREFIX, 'markdeleteRestaurant', { restaurant_id_to_delete });

  return RESTAURANT_REF.doc(restaurant_id_to_delete).set(
    { status: 'deleted' },
    { merge: true }
  );
}

function deleteRestaurant(restaurant_id_to_delete) {
  console.log(LOG_PREFIX, 'deleteRestaurant', { restaurant_id_to_delete });

  return RESTAURANT_REF.doc(restaurant_id_to_delete).delete();
}

export {
  service_category,
  select_values,
  updateRestaurantConfig,
  listAllRestaurants,
  listMarkDeleteRestaurants,
  addRestaurants,
  reserveNewRestaurant,
  deleteRestaurant,
  updateRestaurantConfigByRestId,
  markDeleteRestaurant,
  subscribeAllRestaurants,
  MENY_SERVICE_MENY_LIGHT,
  MENY_SERVICE_MENY,
};
