import React from 'react';
import firebase from 'firebase/app';

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import PhotoCameraTwoToneIcon from '@material-ui/icons/PhotoCameraTwoTone';

import {
  reserveNewAdvertisementFoodDetail,
  addAdvertisementFoodDetail,
} from 'src/modals/advertisement_food_details';

import ShowDebug from 'src/components/ShowDebug';

import active_lang from 'src/langs/jp_en';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  const handleClose = () => {};

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FIRESTORE_FILEPATH = `/AdvertisementFoodDetail`;

export default function AddNewAdvertisementFoodDetailDialog({
  open,
  setOpen,
  handleListAll,
}) {
  let [
    new_advertisement_food_detail,
    setNewAdvertisementFoodDetail,
  ] = React.useState({
    name: '',
    image_urls: ['', '', ''],
    unit_price: 0,
    description: '',
  });
  let [image_content, setImageContent] = React.useState(null);
  let [image_urls, setImageUrls] = React.useState(['', '', '']);
  let [user_updated_image, setUserUpdatedImage] = React.useState(false);
  let [has_image, setHasImage] = React.useState(false);
  const file_ref = React.useRef(null);
  let [file_picture, setFilePicture] = React.useState(null);
  let [file_to_upload, setFileToUpload] = React.useState();

  const handleClose = () => {
    setOpen(false);
    handleListAll();
  };

  const handleUpdateName = e => {
    setNewAdvertisementFoodDetail({
      ...new_advertisement_food_detail,
      name: e.target.value,
    });
  };

  const handleDeleteAdvertisementImage = () => {
    setImageUrls(['', '', '']);
    setImageContent(null);
    setUserUpdatedImage(true);
  };

  const handleFileInput = e => {
    console.log('file_ref', file_ref);
    setFilePicture(file_ref.current.files[0]);
    setImageContent(file_ref.current.files[0]);

    let selectedFile = file_ref.current.files[0];

    var reader = new FileReader();
    reader.onload = function(event) {
      setImageContent(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
    setFileToUpload(selectedFile);

    setHasImage(true);
    console.log(
      'setImageContent(file_ref.current.files[0]);',
      file_ref.current.files[0]
    );
    setUserUpdatedImage(true);
  };

  const handleUpdateUnitPrice = e => {
    setNewAdvertisementFoodDetail({
      ...new_advertisement_food_detail,
      unit_price: e.target.value,
    });
  };

  const handleUpdateDescription = e => {
    setNewAdvertisementFoodDetail({
      ...new_advertisement_food_detail,
      description: e.target.value,
    });
  };

  const performUpload = (advertisement_food_detail_id, file_picture) => {
    let storageRef = firebase.storage().ref();
    let metadata = { contentType: file_picture.type };
    let file_suffix = file_picture.name.split('.').pop();
    let file_path =
      FIRESTORE_FILEPATH + `/${advertisement_food_detail_id}/profile`;

    console.log('file_path', file_path);
    console.log('file_picture', file_picture);

    return storageRef.child(file_path).put(file_picture, metadata);
  };

  const handleAddNewAdvertisementFoodDetail = () => {
    let new_advertisement_food_detail_id = reserveNewAdvertisementFoodDetail();
    console.log(
      'handleAddNewAdvertisementFoodDetail',
      'file_to_upload',
      file_to_upload
    );
    if (file_to_upload != null && file_to_upload != '') {
      let imageUploader = performUpload(
        new_advertisement_food_detail_id,
        file_to_upload
      );

      imageUploader.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {},
        error => {
          // alert('error found during upload image file');
        },
        () => {
          imageUploader.snapshot.ref
            .getDownloadURL()
            .then(function(downloadURL) {
              console.log('File available at', downloadURL);

              let temp = {
                name: new_advertisement_food_detail.name,
                image_urls: [downloadURL, '', ''],
                max_quantity_per_order: 10,
                food_id: 'food_0',
                price: new_advertisement_food_detail.unit_price,
                description: new_advertisement_food_detail.description,
                update_time: '2020-01-02 11:22:33',
                long_name: '',
                unit_price: new_advertisement_food_detail.unit_price,
                min_quantity_per_order: 1,
              };

              addAdvertisementFoodDetail(temp)
                .then(docRef => {
                  // alert('add advertisement food detail with food image done');

                  handleClose();
                })
                .catch(err => {
                  // alert('error during adding advertisement food detail');
                  console.log(
                    'error during adding advertisement food detail',
                    err
                  );
                });
            });
        }
      );
    } else {
      let temp = {
        name: new_advertisement_food_detail.name,
        image_urls: ['', '', ''],
        max_quantity_per_order: 10,
        food_id: 'food_0',
        price: new_advertisement_food_detail.unit_price,
        description: new_advertisement_food_detail.description,
        update_time: '2020-01-02 11:22:33',
        long_name: '',
        unit_price: new_advertisement_food_detail.unit_price,
        min_quantity_per_order: 1,
      };

      addAdvertisementFoodDetail(temp)
        .then(docRef => {
          // alert('add advertisement food detail without image done');
          handleClose();
        })
        .catch(err => {
          // alert('error during adding restaurant');
        });
    }
  };

  const ImageEditor = ({ image_urls }) => {
    if (image_urls[0] == '') {
      if (image_content != null) {
        return (
          <>
            {/* <img src={image_urls[0]} /> */}
            <div
              style={{
                width: '100%',
                height: '33vh',
                backgroundImage: `url(${image_content})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></div>
            <Box m="0.5rem">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleDeleteAdvertisementImage}
                >
                  delete image
                </Button>
              </div>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <ShowDebug>
              <pre>{JSON.stringify(image_content, null, 2)}</pre>
            </ShowDebug>
            <div>
              <input
                type="file"
                onChange={handleFileInput}
                ref={file_ref}
                style={{ display: 'none' }}
              />
              <Button
                onClick={e => {
                  file_ref.current.click();
                }}
                startIcon={<PhotoCameraTwoToneIcon />}
              >
                圖片の上傳の
              </Button>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          {/* <img src={image_urls[0]} /> */}
          <div
            style={{
              width: '100%',
              height: '33vh',
              backgroundImage: `url(${image_urls[0]})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          ></div>
          <Box m="0.5rem">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={handleDeleteAdvertisementImage}
              >
                delete image
              </Button>
            </div>
          </Box>
        </>
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={false}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ShowDebug>
          <pre>{JSON.stringify(new_advertisement_food_detail, null, 2)}</pre>
        </ShowDebug>
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <ImageEditor
                image_urls={new_advertisement_food_detail.image_urls}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>{'New advertisements Food Detail'}</Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="name"
                  variant="outlined"
                  value={new_advertisement_food_detail.name}
                  onChange={handleUpdateName}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="unit_price"
                  variant="outlined"
                  value={new_advertisement_food_detail.unit_price}
                  onChange={handleUpdateUnitPrice}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  multiline
                  value={new_advertisement_food_detail.description}
                  onChange={handleUpdateDescription}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
          <Button onClick={handleAddNewAdvertisementFoodDetail} color="primary">
            {active_lang.ADMIN_USER_UPDATE_OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
