import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  IconButton,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';
import ShowDebugJson from 'src/components/ShowDebugJson';
import active_lang from 'src/langs/jp_en';

import Actions from './Actions';
import { subscribeAllRestaurants } from 'src/modals/restaurant_config';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export default function ListRestaurants({
  className,
  restaurants,
  query_list_all_restaurants,
  list_all_user_result,
  ...rest
}) {
  const classes = useStyles();
  const [listRestaurant, setListRestaurant] = React.useState([]);

  React.useEffect(() => {
    subscribeAllRestaurants().onSnapshot(querySnapshot => {
      const a_d = [];
      const options = [];
      querySnapshot.forEach(doc => {
        a_d.push({ id: doc.id, data: doc.data() });
      });

      a_d
        .sort((a, b) => a.data.name - b.data.name)
        .forEach(d =>
          options.push({ name: d.data.name, id: d.id, data: d.data })
        );

      setListRestaurant(options);
    });
  }, []);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}></Box>
        </PerfectScrollbar>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{active_lang.RESTAURANT_ID}</TableCell>
              <TableCell>{active_lang.ADMIN_USER_NAME}</TableCell>
              <TableCell>{active_lang.ADMIN_USER_SERVICE_PLAN}</TableCell>
              <TableCell>{active_lang.ADMIN_USER_ACTIONS}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {listRestaurant.map(restaurants_id_and_data => {
              let restaurant = restaurants_id_and_data.data;
              let restaurant_id = restaurants_id_and_data.id;

              return (
                <>
                  <TableRow name={restaurant.id} hover key={restaurant.id}>
                    <TableCell>{restaurant_id}</TableCell>
                    <TableCell>{restaurant.name}</TableCell>
                    <TableCell>{restaurant.meny_service_class.name}</TableCell>
                    <TableCell>
                      <Actions
                        restaurant_id={restaurant_id}
                        data={restaurant}
                        customer_id_and_data={restaurants_id_and_data}
                        query_list_all_restaurants={query_list_all_restaurants}
                        list_all_user_result={list_all_user_result}
                        all_restaurants_result={{ data: restaurants }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <ShowDebugJson>{JSON.stringify(restaurants)}</ShowDebugJson>
    </>
  );
}
