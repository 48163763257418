function getDataById(all_data, wanted_id) {
  let found = false;
  let found_data = {};
  for (let i = 0; i < all_data.length; i++) {
    if (all_data[i].id === wanted_id) {
      found = true;
      found_data = all_data[i];
      break;
    }
  }

  if (found) {
    return found_data;
  } else {
    return undefined;
  }
}

export default getDataById;
