import React from 'react';
import app from 'src/Firebase/config';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/Firebase/context';

import writeLog from 'src/helpers/writeLog';
import { field_values } from 'src/modals/logging';

export default function LogoutView() {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const PUBLIC_URL = process.env.PUBLIC_URL;

  React.useEffect(() => {
    writeLog({
      event_type: field_values.event_type.ADMIN_USER_LOGOUT,
      content: { user },
    })
      .then(() => {})
      .finally(() => {
        app.auth().signOut();
        navigate(`${PUBLIC_URL}/login`, { replace: true });
      });
  }, []);

  return (
    <div>
      <div>logged out</div>
      <div>
        <a href="/login">back to login</a>
      </div>
    </div>
  );
}
