import React from 'react';
import { Box } from '@material-ui/core';
import ShowDebug from 'src/components/ShowDebug';

export default function AdConfigureSlotOrderListSubform({ formik }) {
  return (
    <>
      <ShowDebug>
        empty as advertistment configure slot=order list selected
      </ShowDebug>
    </>
  );
}
