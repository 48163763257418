import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';

import AdvertisementFoodDetail from 'src/views/AdvertisementFoodDetail';

const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const routes = [
  {
    path: `${ENV_PUBLIC_URL}/app`,
    element: <DashboardLayout />,
    children: [
      {
        path: 'advertisement_food_detail',
        element: <AdvertisementFoodDetail />,
      },
    ],
  },
];

export default routes;
