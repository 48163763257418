import React from 'react';

import {
  Box,
  Container,
  makeStyles,
  Typography,
  Link,
  Button,
} from '@material-ui/core';

import Page from 'src/components/Page';

import ShowDebug from 'src/components/ShowDebug';

import Toolbar from './Toolbar';

import active_lang from 'src/langs/jp_en';

import ListAdvertisementFoodDetail from './ListAdvertisementFoodDetail';

import {
  loadAdvertisementFoodDetails,
  ADVERTISEMENT_FOOD_DETAIL_TEST,
} from 'src/modals/advertisement_food_details';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

export default function AdvertisementFoodDetail() {
  const classes = useStyles();
  const [debug, setDebug] = React.useState();
  const [
    advertisement_food_details,
    setAdvertisementFoodDetails,
  ] = React.useState([]);

  const handleListAllAdvertisementFoodDetails = () => {
    loadAdvertisementFoodDetails()
      .then(ss => {
        let a_d = [];
        ss.forEach(doc => a_d.push({ id: doc.id, data: doc.data() }));
        return a_d;
      })
      .then(a_d => {
        console.log('a_d', a_d);
        setAdvertisementFoodDetails(a_d);
      });
  };

  React.useEffect(() => {
    // setAdvertisementFoodDetails(ADVERTISEMENT_FOOD_DETAIL_TEST);
    loadAdvertisementFoodDetails()
      .then(ss => {
        let a_d = [];
        ss.forEach(doc => a_d.push({ id: doc.id, data: doc.data() }));

        return a_d;
      })
      .then(a_d => {
        console.log('a_d', a_d);
        setAdvertisementFoodDetails(a_d);
      });
  }, []);

  return (
    <>
      <Page
        className={classes.root}
        title={active_lang.ADMIN_ADVERTISEMENT_ACTIONS}
      >
        <Container maxWidth={false}>
          <ShowDebug>
            <Box style={{ textAlign: 'center' }}>
              <Button onClick={handleListAllAdvertisementFoodDetails}>
                handleListAllAdvertisementFoodDetails
              </Button>
            </Box>
          </ShowDebug>
          <Toolbar
            handleListAllAdvertisementFoodDetails={
              handleListAllAdvertisementFoodDetails
            }
          />
          <Box mb="1rem">
            <Typography variant="h4" color="textPrimary">
              {active_lang.ADMIN_ADVERTISEMENT_ACTIONS}
            </Typography>
          </Box>
          <Box>
            <ListAdvertisementFoodDetail
              advertisement_food_details={advertisement_food_details}
              handleListAllAdvertisementFoodDetails={
                handleListAllAdvertisementFoodDetails
              }
            />
          </Box>
          <ShowDebug>
            <pre>{JSON.stringify(debug, null, 2)}</pre>
          </ShowDebug>
        </Container>
      </Page>
    </>
  );
}
