import React from 'react';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Slide,
} from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import active_lang from 'src/langs/jp_en';
import test_data from './test_data';
import init_advertisement_tag from './init_new_advertisement_tag';

import { addNewAdvertisementTag } from 'src/modals/advertisement_tag';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNewAdvertisementTag({
  refreshAddAdvertisementTag,
  open,
  setOpen,
}) {
  let [debug, setDebug] = React.useState(null);
  let [advertisement_tag, setAdvertisementTag] = React.useState(
    init_advertisement_tag
  );
  const handleUpdateAdvertisementTags = e => {};

  const handleUpdate = e => {
    setAdvertisementTag({
      ...advertisement_tag,
      [e.target.name]: e.target.value,
    });
  };
  const handleClose = () => {
    refreshAddAdvertisementTag();
    setAdvertisementTag(init_advertisement_tag);
    setOpen(false);
  };

  const handleAddNewAdvertisementTag = () => {
    addNewAdvertisementTag(advertisement_tag)
      .then(doc_id => {
        handleClose();
      })
      .catch(err => {
        alert('error during adding new advertisement tag');
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={false}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ShowDebug>
          <pre>{JSON.stringify(debug, null, 2)}</pre>
        </ShowDebug>
        <DialogTitle id="alert-dialog-slide-title">
          <Box mb="1rem">
            <Typography variant="h4" color="textPrimary">
              {active_lang.NEW_ADVERTISEMENT_TAG_TITLE}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Box m="0.5rem">
                  <TextField
                    fullWidth
                    label={active_lang.ADVERTISEMENT_TAG_TITLE}
                    name="title"
                    onInput={handleUpdate}
                    required
                    value={advertisement_tag.title}
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m="0.5rem">
                  <TextField
                    fullWidth
                    label={active_lang.ADVERTISEMENT_TAG_REMARKS}
                    name="remarks"
                    onInput={handleUpdate}
                    required
                    value={advertisement_tag.remarks}
                    variant="outlined"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {active_lang.ADMIN_USER_UPDATE_CANCEL}
            </Button>
            <Button onClick={handleAddNewAdvertisementTag} color="primary">
              {active_lang.ADMIN_USER_UPDATE_OK}
            </Button>
          </DialogActions>
        </DialogContent>
        <ShowDebug>
          <pre>{JSON.stringify(advertisement_tag)}</pre>
        </ShowDebug>
      </Dialog>
    </>
  );
}
