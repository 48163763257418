import { useMutation } from 'react-query';
import { markDeleteStadium } from 'src/modals/stadium_config';

export default function useMutateUserDeleteStadiumById() {
  const mutation = useMutation(stadium_id_to_delete => {
    return markDeleteStadium(stadium_id_to_delete);
  });

  return mutation;
}
