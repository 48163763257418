import React from 'react';
import { Box, TextField, Container, Typography } from '@material-ui/core';

export default function ShowDebugJson({ children }) {
  const handleFocus = event => event.target.select();

  return (
    <>
      <Box
        style={{
          display: process.env.NODE_ENV == 'development' ? 'block' : 'none',
          backgroundColor: 'gold',
          width: '100%',
          maxheight: '3rem',
          fontFamily: 'monospace',
          fontSize: 'xx-small',
        }}
      >
        <TextField
          value={children}
          size="small"
          onFocus={handleFocus}
          inputProps={{
            style: {
              fontFamily: 'monospace',
              fontSize: 'xx-small',
            },
          }}
          fullWidth
        />
      </Box>
    </>
  );
}
