import React from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, isUndefined } from 'lodash-es';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
} from '@material-ui/core';
import AutoCompleteRestaurantOperators from './AutoCompleteRestaurantOperators';
import active_lang from 'src/langs/jp_en';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import ImageHandler from './ImageHandler';
import useUpdateRestaurantInfo from 'src/hooks/useUpdateRestaurantInfo';
import isBase64Image from 'src/utils/isBase64Image';
import isHttpLink from 'src/utils/isHttpLink';
import { ENQUEUE_SUCCESS_CONFIG } from 'src/consts/ENQUEUE_CONFIG';
import { useStyles, Transition } from 'src/consts/styles';
import useQueryListAllStadiums from 'src/hooks/useQueryListAllStadiums';

const RESTAURANT_ACTIVE_DEFAULT_VALUE = true;

export default function EditRestaurantDialog({
  open,
  setOpen,
  initial_data,
  restaurant_id,
  refetch,
  list_all_user_result,
}) {
  let classes = useStyles();
  let mutateFirestoreUpload = useFirestoreUpload();
  let mutationUpdateRestaurantInfo = useUpdateRestaurantInfo();
  const [project, setProject] = React.useState(projects[0]);
  const [stadium, setStadium] = React.useState([]);
  let query_list_all_stadiums = useQueryListAllStadiums();

  React.useEffect(() => {
    setProject(initial_data.stadium_id ? projects[1] : projects[0]);
  }, [initial_data, open]);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    formik.resetForm();
    // alert('EditRestaurantDialog handleClose');
    setOpen(false);
  };

  const stadiums = React.useMemo(() => {
    if (!query_list_all_stadiums.data) {
      return [];
    }

    return query_list_all_stadiums.data.map(stadium => ({
      id: stadium.id,
      name: stadium.name,
    }));
  }, [query_list_all_stadiums.data]);

  React.useEffect(() => {
    const selectedStadium = stadiums.find(
      stadium => stadium.id === initial_data.stadium_id
    );

    if (selectedStadium) {
      setStadium(selectedStadium);
    } else {
      setStadium(null);
    }
  }, [stadiums, initial_data, open]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initial_data,
      isSkippableToAssignTable: initial_data.isSkippableToAssignTable
        ? initial_data.isSkippableToAssignTable
        : false,
      isEnableBellRing: initial_data.isEnableBellRing
        ? initial_data.isEnableBellRing
        : false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(active_lang.RESTAURANT_NAME_IS_REQUIRED),
      // ip_printer: Yup.string().required(active_lang.RESTAURANT_NAME_IS_REQUIRED),
      restaurant_operators: Yup.array().min(
        1,
        active_lang.AT_LEAST_ONE_RESTAURANT_OPERATOR_REQUIRED
      ),
      // stadium_id: Yup.string().test(
      //   'is-stadium-id',
      //   'Invalid stadium ID',
      //   value => {
      //     return typeof value === 'string' && value.trim() !== '';
      //   }
      // ),
    }),
    onSubmit: async values => {
      const fieldsToDelete = [];

      if (project.id === 0) {
        fieldsToDelete.push('ip_printer', 'stadium_id', 'merchant_id');
      }
      let file_path = `restaurant_info/${restaurant_id}/restaurant_profile`;
      let image_base64 = values.restaurant_profile_pic;

      if (isBase64Image(image_base64)) {
        mutateFirestoreUpload
          .mutateAsync({
            file_path,
            file_base64: image_base64,
          })
          .then(data => {
            return mutationUpdateRestaurantInfo.mutateAsync({
              rest_id: restaurant_id,
              payload: { ...values, restaurant_profile_pic: data.download_url },
              fieldsToDelete,
            });
          })
          .then(() => {
            enqueueSnackbar(
              active_lang.RESTAURANT_CONFIG_UPDATE_SUCCESS,
              ENQUEUE_SUCCESS_CONFIG
            );
            // alert('1');

            refetch();
          })
          .then(() => {
            handleClose();
          })
          .catch(err => {
            console.error('err', err);
          });
      } else if (isHttpLink(values.restaurant_profile_pic)) {
        delete values.image_urls;

        mutationUpdateRestaurantInfo
          .mutateAsync({
            rest_id: restaurant_id,
            payload: values,
            fieldsToDelete,
          })
          .then(() => {
            enqueueSnackbar(
              active_lang.RESTAURANT_CONFIG_UPDATE_SUCCESS,
              ENQUEUE_SUCCESS_CONFIG
            );

            // alert('2');

            refetch();
          })
          .then(() => {
            handleClose();
          })
          .catch(err => {
            console.error('err', err);
          });
      } else {
        mutationUpdateRestaurantInfo
          .mutateAsync({
            rest_id: restaurant_id,
            payload: {
              ...values,
              restaurant_profile_pic: '',
            },
            fieldsToDelete,
          })
          .then(() => {
            enqueueSnackbar(
              active_lang.RESTAURANT_CONFIG_UPDATE_SUCCESS,
              ENQUEUE_SUCCESS_CONFIG
            );

            // alert('3');

            refetch();
          })
          .then(() => {
            handleClose();
          })
          .catch(err => {
            console.error('err', err);
          });
      }
    },
  });

  return (
    <>
      {list_all_user_result.isLoading ? (
        <>{active_lang.LOADING_RESTAURANTS_AND_USERS}</>
      ) : (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth
          >
            <form
              onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <DialogContent>
                <Box mt="1rem">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="projects"
                      options={projects}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="ProjectsSelect"
                          variant="outlined"
                        />
                      )}
                      value={project}
                      disableClearable
                      onChange={(event, newValue) => setProject(newValue)}
                    />
                  </FormControl>
                </Box>

                <Box mt="1rem">
                  <FormControl fullWidth>
                    <TextField
                      id="name"
                      {...formik.getFieldProps('name')}
                      className={classes.TextInput}
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ style: { padding: '1rem' } }}
                      label={active_lang.ADMIN_RESTAURANT_NAME}
                      variant="outlined"
                      fullWidth
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Box className={classes.field_error_highlight}>
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors.name}</Box>
                      </Box>
                    ) : null}
                  </FormControl>
                </Box>

                <Box mt={'1rem'}>
                  <FormControl fullWidth>
                    <ImageHandler
                      id="restaurant_profile_pic"
                      {...formik.getFieldProps('restaurant_profile_pic')}
                    />
                  </FormControl>
                </Box>

                {project.id === 1 && (
                  <Box mt="1rem">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="stadiums"
                        {...formik.getFieldProps('stadium_id')}
                        options={stadiums}
                        value={stadium}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="StadiumsSelect"
                            variant="outlined"
                          />
                        )}
                        disableClearable
                        onChange={(event, newValue) => {
                          setStadium(newValue);
                          formik.setFieldValue('stadium_id', newValue.id);
                        }}
                      />
                    </FormControl>
                  </Box>
                )}

                {project.id === 1 && (
                  <Box mt="1rem">
                    <FormControl fullWidth>
                      <TextField
                        id="ip_printer"
                        {...formik.getFieldProps('ip_printer')}
                        className={classes.TextInput}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ style: { padding: '1rem' } }}
                        label={active_lang.IP_PRINTER}
                        variant="outlined"
                        fullWidth
                      />
                      {formik.touched.ip_printer && formik.errors.ip_printer ? (
                        <Box className={classes.field_error_highlight}>
                          <Box pr="1rem">
                            <WarningIcon fontSize="small" />
                          </Box>
                          <Box>{formik.errors.ip_printer}</Box>
                        </Box>
                      ) : null}
                    </FormControl>
                  </Box>
                )}

                {project.id === 1 && (
                  <Box mt="1rem">
                    <FormControl fullWidth>
                      <TextField
                        id="merchant_id"
                        {...formik.getFieldProps('merchant_id')}
                        className={classes.TextInput}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ style: { padding: '1rem' } }}
                        label={active_lang.MERCHANT_ID}
                        variant="outlined"
                        fullWidth
                      />
                      {formik.touched.merchant_id &&
                      formik.errors.merchant_id ? (
                        <Box className={classes.field_error_highlight}>
                          <Box pr="1rem">
                            <WarningIcon fontSize="small" />
                          </Box>
                          <Box>{formik.errors.merchant_id}</Box>
                        </Box>
                      ) : null}
                    </FormControl>
                  </Box>
                )}

                {project.id !== 1 && (
                  <Box mt="1rem">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="meny_service_class"
                        {...formik.getFieldProps('meny_service_class')}
                        options={service_category}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="RestaurantServiceCategorySelect"
                            variant="outlined"
                          />
                        )}
                        disableClearable
                        onChange={(event, newValue) =>
                          formik.setFieldValue('meny_service_class', newValue)
                        }
                      />
                    </FormControl>
                  </Box>
                )}

                <Box mt="1rem">
                  {isUndefined(list_all_user_result) ||
                  list_all_user_result.isLoading ? (
                    <>{active_lang.LOADING_USERS}</>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <AutoCompleteRestaurantOperators
                          formik={formik}
                          list_all_user_result={list_all_user_result}
                        />
                      </FormControl>
                    </>
                  )}
                </Box>

                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="enable_rest_ad_control"
                              checked={
                                isUndefined(formik.values) ||
                                isUndefined(
                                  formik.values.enable_rest_ad_control
                                )
                                  ? false
                                  : formik.values.enable_rest_ad_control
                              }
                              // checked={enable_rest_ad_control}
                              {...formik.getFieldProps(
                                'enable_rest_ad_control'
                              )}
                              inputProps={{
                                'aria-label':
                                  active_lang.ENABLE_AD_CONFIG_ON_CMS,
                              }}
                            />
                          }
                          label={active_lang.ENABLE_AD_CONFIG_ON_CMS}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isSkippableToAssignTable"
                              checked={formik.values.isSkippableToAssignTable}
                              onChange={formik.handleChange}
                            />
                          }
                          label="SKIP TABLE ASSIGN"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="restaurant_active"
                              checked={
                                isUndefined(formik.values) ||
                                isUndefined(formik.values.restaurant_active)
                                  ? RESTAURANT_ACTIVE_DEFAULT_VALUE
                                  : formik.values.restaurant_active
                              }
                              {...formik.getFieldProps('restaurant_active')}
                              inputProps={{
                                'aria-label': active_lang.RESTAURANT_ACTIVE,
                              }}
                            />
                          }
                          label={active_lang.RESTAURANT_ACTIVE}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box mt="1rem">
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isEnableBellRing"
                              checked={formik.values.isEnableBellRing}
                              onChange={formik.handleChange}
                            />
                          }
                          label="ENABLE BELL RING FEATURE"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                  type="button"
                  color="primary"
                >
                  {active_lang.AD_EDIT_CANCEL}
                </Button>
                <Button
                  disabled={
                    formik.isSubmitting ||
                    !formik.dirty ||
                    !isEqual(formik.errors, {})
                  }
                  type={'submit'}
                  color="primary"
                  variant="contained"
                >
                  {active_lang.AD_EDIT_CONFIRM}
                </Button>
              </DialogActions>
            </form>
            {/* <ShowDebug>
              <ShowDebugJson>{JSON.stringify(formik.values)}</ShowDebugJson>
              <ShowDebugJson>{formik.isSubmitting}</ShowDebugJson>
              <ShowDebugJson>{!formik.dirty}</ShowDebugJson>
              <ShowDebugJson>{!isEqual(formik.errors, {})}</ShowDebugJson>
              <ShowDebugJson>
                {formik.isSubmitting || !isEqual(formik.errors, {})}
              </ShowDebugJson>
            </ShowDebug> */}
          </Dialog>
        </>
      )}
    </>
  );
}

const service_category = [
  // { name: 'account disabled (0)', id: 0 },
  // { name: 'active (1)', id: 1 },
  { name: 'meny_light(4)', id: 4 },
  // { name: 'meny in(8)', id: 8 },
  { name: 'meny go(16)', id: 16 },
  { name: 'meny(32)', id: 32 },
];

const projects = [
  { name: 'MENY', id: 0 },
  {
    name: 'STADIUM',
    id: 1,
  },
];
