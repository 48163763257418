import React from 'react';
import firebase from 'firebase/app';

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import PhotoCameraTwoToneIcon from '@material-ui/icons/PhotoCameraTwoTone';

import { updateAdvertisementFoodDetails } from 'src/modals/advertisement_food_details';

import ShowDebug from 'src/components/ShowDebug';

import active_lang from 'src/langs/jp_en';

const FIRESTORE_FILEPATH = `/AdvertisementFoodDetail`;

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  const handleClose = () => {};

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Loading() {
  return <>edit form loading</>;
}

function ShowEditForm({ open, setOpen, id_data, handleListAll }) {
  let [updated_form, setUpdatedForm] = React.useState({
    image_urls: ['', '', ''],
    name: '',
    unit_price: '',
    description: '',
  });
  let [image_content, setImageContent] = React.useState(null);
  let [file_picture, setFilePicture] = React.useState(null);
  let [has_image, setHasImage] = React.useState(false);
  let [user_updated_image, setUserUpdatedImage] = React.useState(false);
  let [file_to_upload, setFileToUpload] = React.useState();

  const file_ref = React.useRef(null);

  let [image_urls, setImageUrls] = React.useState(updated_form.image_urls);

  const handleClose = () => {
    setOpen(false);
    handleListAll();
  };

  const handleUpdateName = e => {
    setUpdatedForm({ ...updated_form, name: e.target.value });
  };

  const handleUpdateUnitPrice = e => {
    setUpdatedForm({ ...updated_form, unit_price: e.target.value });
  };

  const handleUpdateDescription = e => {
    setUpdatedForm({ ...updated_form, description: e.target.value });
  };

  const performUpload = (advertisement_food_detail_id, file_picture) => {
    let storageRef = firebase.storage().ref();
    let metadata = { contentType: file_picture.type };
    let file_suffix = file_picture.name.split('.').pop();
    let file_path =
      FIRESTORE_FILEPATH + `/${advertisement_food_detail_id}/profile`;

    console.log('file_path', file_path);
    console.log('file_picture', file_picture);

    return storageRef.child(file_path).put(file_picture, metadata);
  };

  const handleUpdateAdvertisementFoodDetail = () => {
    if (user_updated_image) {
      if (file_to_upload != null) {
        let imageUploader = performUpload(id_data.id, file_to_upload);

        imageUploader.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {},
          error => {
            alert('error found during upload image file');
          },
          () => {
            imageUploader.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                let temp_form = {
                  ...updated_form,
                  image_urls: [downloadURL, '', ''],
                };
                updateAdvertisementFoodDetails(id_data.id, temp_form).then(
                  () => {
                    handleClose();
                  }
                );
              });
          }
        );
      } else {
        updateAdvertisementFoodDetails(id_data.id, updated_form).then(() => {
          handleClose();
        });
      }
    } else {
      updateAdvertisementFoodDetails(id_data.id, updated_form).then(() => {
        handleClose();
      });
    }
  };

  const handleDeleteAdvertisementImage = () => {
    // alert('hello delete');
    // setImageUrls(['', '', '']);
    setImageContent(null);
    setUserUpdatedImage(true);
    setFileToUpload(null);
    setUpdatedForm({ ...updated_form, image_urls: ['', '', ''] });
  };

  React.useEffect(() => {
    console.log('image_content', image_content);
  }, [image_content]);

  const handleFileInput = e => {
    console.log('file_ref', file_ref);
    setFilePicture(file_ref.current.files[0]);
    setImageContent(file_ref.current.files[0]);

    let selectedFile = file_ref.current.files[0];

    var reader = new FileReader();
    reader.onload = function(event) {
      setImageContent(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
    setFileToUpload(selectedFile);

    setHasImage(true);
    console.log(
      'setImageContent(file_ref.current.files[0]);',
      file_ref.current.files[0]
    );
    setUserUpdatedImage(true);
  };

  // const ImageEditor = ({ image_urls }) => {
  function ImageEditor({ image_urls }) {
    if (image_urls[0] == '') {
      if (image_content != null) {
        return (
          <>
            {/* <img src={image_urls[0]} /> */}
            image 2
            <div
              style={{
                width: '100%',
                height: '33vh',
                backgroundImage: `url(${image_content})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></div>
            <Box m="0.5rem">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleDeleteAdvertisementImage}
                >
                  delete image
                </Button>
              </div>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <ShowDebug>
              <pre>{JSON.stringify(image_content, null, 2)}</pre>
            </ShowDebug>
            <div>
              <input
                type="file"
                onChange={handleFileInput}
                ref={file_ref}
                style={{ display: 'none' }}
              />
              <Button
                onClick={e => {
                  file_ref.current.click();
                }}
                startIcon={<PhotoCameraTwoToneIcon />}
              >
                圖片の上傳の
              </Button>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          {/* <img src={image_urls[0]} /> */}
          image 1
          <div
            style={{
              width: '100%',
              height: '33vh',
              backgroundImage: `url(${image_urls[0]})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          ></div>
          <Box m="0.5rem">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={handleDeleteAdvertisementImage}
              >
                delete image
              </Button>
            </div>
          </Box>
        </>
      );
    }
  }

  React.useEffect(() => {
    if (id_data != null && Object.keys(id_data).includes('data')) {
      setUpdatedForm(id_data.data);
    }
  }, [id_data]);

  React.useEffect(() => {
    if (id_data != null && Object.keys(id_data).includes('data')) {
      setUpdatedForm(id_data.data);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={false}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ShowDebug>
          <pre>{JSON.stringify(updated_form, null, 2)}</pre>
        </ShowDebug>
        <DialogTitle id="alert-dialog-slide-title"></DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <ImageEditor image_urls={updated_form.image_urls} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>{'edit advertisements'}</Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="name"
                  variant="outlined"
                  value={updated_form.name}
                  onChange={handleUpdateName}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="unit_price"
                  variant="outlined"
                  value={updated_form.unit_price}
                  onChange={handleUpdateUnitPrice}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={'1rem'}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  multiline
                  value={updated_form.description}
                  onChange={handleUpdateDescription}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
          <Button onClick={handleUpdateAdvertisementFoodDetail} color="primary">
            {active_lang.ADMIN_USER_UPDATE_OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function EditAdvertisementFoodDetail({
  open,
  setOpen,
  id_data,
  handleListAll,
}) {
  return id_data == null ? (
    <Loading />
  ) : (
    <ShowEditForm
      id_data={id_data}
      open={open}
      setOpen={setOpen}
      handleListAll={handleListAll}
    />
  );
}
