import React from 'react';

import SelectFoodMenuCategory from './SelectFoodMenuCategory';

export default function GotoFoodMenuSubform({ formik, food_details }) {
  return (
    <>
      <SelectFoodMenuCategory formik={formik} food_details={food_details} />
    </>
  );
}
