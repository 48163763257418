import { SERVICE_DISABLED, SERVICE_MENY_LIGHT, SERVICE_MENY_IN, SERVICE_MENY_GO, SERVICE_MENY } from 'src/configs/meny_service';

const test_restaurant_with_profile_pic = {
  id: 'Q6995NBl2DwJhDlQNcp1',
  data: {
    service_tags: [
      {
        id: 1,
        name: 'beer',
      },
    ],
    restaurant_operators: [
      {
        uid: '3tY19OCKZAcCMMusV8XvQCBG9lE3',
        email: 'rest1@gmail.com',
      },
    ],
    status: 'active',
    restaurant_profile_pic:
      'https://firebasestorage.googleapis.com/v0/b/lynked-demo-tryout.appspot.com/o/restaurant_profile_pic%2FBl4ExOwKdEiIRyfTGwbp%2Fprofile?alt=media&token=f0ae23b7-7463-4aa8-96fd-798928e362c9',
    meny_service_class: {
      name: 'meny_light(4)',
      id: 4,
    },
    name: 'test-restaurant',
  },
};

const restaurants = [
  {
    id: '111',
    data: {
      meny_service_class: {
        id: 8,
        name: 'meny in(8)',
      },
      restaurant_operators: [
        {
          name: 'user1@123.com',
          uid: '0Kp5337qkNav4sRngUifnGIL76w2',
        },
        {
          uid: 'PsCFIwKxsthfYTjKn3oLGxdQ54V2',
          name: 'admin1@gmail.com',
        },
      ],
      service_tags: [
        {
          name: 'beer',
          id: 1,
        },
        {
          name: 'fast-food',
          id: 2,
        },
      ],
      name: 're1111staurant_1',
    },
  },
];

export default restaurants;
