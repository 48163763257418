import React from 'react';
import { Box, TextField, Container, Typography } from '@material-ui/core';

export default function ShowDebug({ children }) {
  return (
    <>
      <Box
        style={{
          display: process.env.NODE_ENV == 'development' ? 'block' : 'none',
          backgroundColor: 'gold',
          width: '100%',
          maxheight: '3rem',
          fontFamily: 'monospace',
          fontSize: 'x-small',
        }}
      >
        {children}
      </Box>
    </>
  );
}
