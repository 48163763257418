import { isUndefined } from 'lodash-es';

function lookupFoodCategoryTitleByCatId(food_details, cat_id) {
  if (['order_list', 'food_detail'].includes(cat_id)) {
    return cat_id;
  } else {
    if (
      isUndefined(food_details) ||
      isUndefined(food_details.columns[cat_id])
    ) {
      return undefined;
    }
  }
  return food_details.columns[cat_id].title;
}

export default lookupFoodCategoryTitleByCatId;
