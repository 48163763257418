import { useMutation } from 'react-query';
import { addAdvertisementByAdvertisementId } from 'src/modals/advertisement_config';

export default function useMutateAddAdvertisement() {
  const mutation = useMutation(({ advertisement_id, payload }) => {
    console.log(
      'useMutateAddAdvertisement',
      'advertisement_id',
      advertisement_id
    );
    console.log('useMutateAddAdvertisement', 'payload', payload);
    return addAdvertisementByAdvertisementId(advertisement_id, payload);
  });

  return mutation;
}
