const ERR_SERVICE_CLASS_NOT_FOUND = 'service class not found';

// customer means restaurant customer
// user means restaurant operator

// account disabled
const USER_SUSPENDED = 0;
const USER_ENABLED = 1;

// meny service class
// meny light ( Advertising e-menu with help button)
const SERVICE_SUSPENDED = 0;
const SERVICE_ENABLED = 1;
const SERVICE_DISABLED = 2;

const SERVICE_MENY_LIGHT = 4;

// meny in (dine in flow)
const SERVICE_MENY_IN = 8;

// meny go (to go flow)
const SERVICE_MENY_GO = 16;

// meny (pre-order & pick up flow)
const SERVICE_MENY = 32;

// meny_class
const USER_SERVICE_DEFINITIONS = {};
USER_SERVICE_DEFINITIONS[SERVICE_SUSPENDED] = { name: 'account suspended (0)', explain: 'account is disabed' };
USER_SERVICE_DEFINITIONS[SERVICE_ENABLED] = { name: 'active (1)', explain: 'account is active' };
USER_SERVICE_DEFINITIONS[SERVICE_DISABLED] = { name: 'account disabled (2)', explain: 'account is disabed' };
USER_SERVICE_DEFINITIONS[SERVICE_MENY_LIGHT] = { name: 'meny_light(4)', explain: 'meny_light description' };
USER_SERVICE_DEFINITIONS[SERVICE_MENY_IN] = { name: 'meny in(8)', explain: 'meny in description' };
USER_SERVICE_DEFINITIONS[SERVICE_MENY_GO] = { name: 'meny go(16)', explain: 'meny go description' };
USER_SERVICE_DEFINITIONS[SERVICE_MENY] = { name: 'meny(32)', explain: 'meny description' };

const MENY_SERVICE_DEFINITIONS = {};
MENY_SERVICE_DEFINITIONS[SERVICE_DISABLED] = { name: 'account disabled', explain: 'account is disabed' };
MENY_SERVICE_DEFINITIONS[SERVICE_MENY_LIGHT] = { name: 'meny_light', explain: 'meny_light description' };
MENY_SERVICE_DEFINITIONS[SERVICE_MENY_IN] = { name: 'meny in', explain: 'meny in description' };
MENY_SERVICE_DEFINITIONS[SERVICE_MENY_GO] = { name: 'meny go', explain: 'meny go description' };
MENY_SERVICE_DEFINITIONS[SERVICE_MENY] = { name: 'meny', explain: 'meny description' };

function lookupUserServiceDefinitions(service_class_in) {
  return USER_SERVICE_DEFINITIONS[service_class_in];
}

function lookupUserServiceName(service_class_in) {
  try {
    return lookupUserServiceDefinitions(service_class_in).name;
  } catch (error) {
    console.error('error during lookup service definitions', service_class_in);
  }
}

function lookupUserServiceExplain(service_class_in) {
  try {
    return lookupUserServiceDefinitions(service_class_in).explain;
  } catch (error) {
    console.error('error during lookup service class', service_class_in);
  }
}

function lookupMenyServiceClass(meny_service_class_in) {
  return MENY_SERVICE_DEFINITIONS[meny_service_class_in];
}

function lookupMenyServiceName(meny_service_class_in) {
  return lookupMenyServiceClass(meny_service_class_in).name;
}

export {
  SERVICE_DISABLED,
  SERVICE_MENY_LIGHT,
  SERVICE_MENY_IN,
  SERVICE_MENY_GO,
  SERVICE_MENY,
  USER_SUSPENDED,
  USER_ENABLED,
  lookupUserServiceName,
  lookupUserServiceExplain,
  lookupMenyServiceName,
};
