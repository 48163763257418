import React from 'react';

import {
  DialogTitle,
  Dialog,
  Grid,
  Button,
  Box,
  DialogActions,
} from '@material-ui/core';
import active_lang from 'src/langs/jp_en';
import { Transition } from './styles';

import { LYNKED_RED } from 'src/consts/colors';
import ShowDebugJson from 'src/components/ShowDebugJson';
import useMutateDeleteAdvertisementById from 'src/hooks/useMutateDeleteAdvertisementById';

import EnqueueAlert from 'src/contexts/EnqueueAlertContext';

export default function ConfirmDeleteAdvertisementDialog({
  open,
  setOpen,
  advertisement_to_delete,
  refetch,
}) {
  let { showEnqueue } = React.useContext(EnqueueAlert);
  let mutationDeleteAdvertisementById = useMutateDeleteAdvertisementById();

  let [delete_button_disabled, setDeleteButtonDisabled] = React.useState(false);
  let [cancel_button_disabled, setCancelButtonDisabled] = React.useState(false);
  let [ui_disabled, setUiDisabled] = React.useState(false);

  let delete_id = advertisement_to_delete.id;

  const handleClose = () => {
    setOpen(false);
    refetch();
  };
  const handleConfirmDeleteAdvertisement = () => {
    setUiDisabled(true);
    showEnqueue.warning(active_lang.DELETE_ADVERTISEMENT_IN_PROGRESS);

    mutationDeleteAdvertisementById
      .mutateAsync(delete_id)
      .then(() => {
        handleClose();
        showEnqueue.success(active_lang.DELETE_ADVERTISEMENT_DONE);
      })
      .catch(err => {
        showEnqueue.error(active_lang.DELETE_ADVERTISEMENT_ERROR);
        console.log('err', err);
      })
      .finally(() => {
        setUiDisabled(false);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disabled
        maxWidth={'lg'}
        keepMounted
      >
        <ShowDebugJson>{JSON.stringify(advertisement_to_delete)}</ShowDebugJson>
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>{active_lang.ASK_DELETE_RESTAURANT}</Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogActions>
          <Button
            disabled={cancel_button_disabled || ui_disabled}
            onClick={handleClose}
            color="primary"
          >
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
          <Button
            onClick={handleConfirmDeleteAdvertisement}
            disabled={delete_button_disabled || ui_disabled}
            variant={'contained'}
            style={{ backgroundColor: LYNKED_RED }}
          >
            {active_lang.ADMIN_USER_UPDATE_OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
