import React from 'react';
import { IconButton } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import EditRestaurantDialog from 'src/components/EditRestaurantDialog';
import ConfirmDeleteRestaurantDialog from 'src/views/EditRestaurantInfo/ConfirmDeleteRestaurantDialog';
import ShowRestaurantQRCodes from 'src/views/EditRestaurantInfo/ShowRestaurantQRCodes';
import CopyFoodmenuDialog from './CopyFoodmenuDialog';

export default function Actions({
  restaurant_id,
  data,
  customer_id_and_data,
  query_list_all_restaurants,
  list_all_user_result,
  all_restaurants_result,
}) {
  const [
    open_edit_restaurant_dialog,
    setOpenEditRestaurantDialog,
  ] = React.useState(false);

  const [
    open_confirm_delete_restaurant_dialog,
    setOpenConfirmDeleteRestaurantDialog,
  ] = React.useState(false);

  const [open_restaurant_qr_codes, setOpenRestaurantQRCodes] = React.useState(
    false
  );

  const [open_copy_foodmenu, setOpenCopyFoodmenu] = React.useState(false);

  return (
    <>
      <EditRestaurantDialog
        restaurant_id={restaurant_id}
        open={open_edit_restaurant_dialog}
        setOpen={setOpenEditRestaurantDialog}
        initial_data={customer_id_and_data.data}
        refetch={query_list_all_restaurants.refetch}
        list_all_user_result={list_all_user_result}
      />

      <ConfirmDeleteRestaurantDialog
        open={open_confirm_delete_restaurant_dialog}
        setOpen={setOpenConfirmDeleteRestaurantDialog}
        restaurant_to_delete={customer_id_and_data}
        // setRestaurantToDelete={() => {}}
        refetch={query_list_all_restaurants.refetch}
      />

      <ShowRestaurantQRCodes
        open={open_restaurant_qr_codes}
        setOpen={setOpenRestaurantQRCodes}
        restaurant_info={customer_id_and_data}
      />

      <CopyFoodmenuDialog
        open={open_copy_foodmenu}
        setOpen={setOpenCopyFoodmenu}
        dst_rest_id={customer_id_and_data.id}
        all_restaurants_result={all_restaurants_result}
      />

      <IconButton onClick={() => setOpenEditRestaurantDialog(true)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => setOpenConfirmDeleteRestaurantDialog(true)}>
        <DeleteForeverIcon />
      </IconButton>

      {!data.stadium_id && (
        <IconButton onClick={() => setOpenRestaurantQRCodes(true)}>
          <LinkOutlinedIcon />
        </IconButton>
      )}

      {!data.stadium_id && (
        <IconButton onClick={() => setOpenCopyFoodmenu(true)}>
          <FileCopyIcon />
        </IconButton>
      )}
    </>
  );
}
