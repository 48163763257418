import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

const Pagination = ({ data, rowsPerPage, onPageChange }) => {
  const [page, setPage] = React.useState(0);
  const maxPagesToShow = 5;
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let firstPage = Math.max(1, page - Math.floor((maxPagesToShow - 1) / 2));
    let lastPage = Math.min(totalPages, firstPage + maxPagesToShow - 1);

    if (firstPage !== 1) {
      pageNumbers.push(
        <Box key={1} className="pagination-item">
          <Button onClick={() => handleChangePage(0)}>1</Button>
        </Box>
      );
    }

    if (firstPage > 2) {
      pageNumbers.push(
        <Box key="startEllipsis" className="pagination-item">
          <Typography>...</Typography>
        </Box>
      );
    }

    for (let i = firstPage; i <= lastPage; i++) {
      pageNumbers.push(
        <Box key={i} className="pagination-item">
          <Button onClick={() => handleChangePage(i - 1)} disabled={i - 1 === page}>
            {i}
          </Button>
        </Box>
      );
    }

    if (lastPage < totalPages - 1) {
      pageNumbers.push(
        <Box key="endEllipsis" className="pagination-item">
          <Typography>...</Typography>
        </Box>
      );
    }

    if (lastPage !== totalPages) {
      pageNumbers.push(
        <Box key={totalPages} className="pagination-item">
          <Button onClick={() => handleChangePage(totalPages - 1)}>{totalPages}</Button>
        </Box>
      );
    }

    return pageNumbers;
  };

  return (
    <Box display="flex" justifyContent="center" marginTop={2}>
      {renderPageNumbers()}
    </Box>
  );
};

export default Pagination;
