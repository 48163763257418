import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { isNull, isUndefined } from 'lodash-es';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { AuthContext } from 'src/Firebase/context';

import NavItem from './NavItem';

import production_items from './production_items';
import debug_items from './debug_items';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

let items = [...production_items];
if (process.env.NODE_ENV == 'development') {
  debug_items.forEach(item => {
    items.push(item);
  });
}

function DrawerContent({ user }) {
  const classes = useStyles();

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" flexDirection="column" p={2}>
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            src={user.avatar || user.photoURL}
            to="/app/account"
          />
          <Typography className={classes.name} color="textPrimary" variant="h5">
            {user.displayName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {user.email}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {items.map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                nav_id={item.id}
              />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
        <Box p={2} m={2} bgcolor="background.dark"></Box>
      </Box>
    </>
  );
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  let [show_content, setShowContent] = React.useState(false);

  let { user } = useContext(AuthContext);

  React.useEffect(() => {
    if (isUndefined(user) || isNull(user)) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <>
      {show_content ? (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              <DrawerContent user={user} />
            </Drawer>
          </Hidden>
          <Hidden mdDown>
            <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
            >
              <DrawerContent user={user} />
            </Drawer>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              <Box
                style={{
                  width: '100%',
                  height: '100vh',

                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                loading...
              </Box>
            </Drawer>
          </Hidden>
          <Hidden lgDown>
            <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
            >
              <Box
                style={{
                  width: '100%',
                  height: '100vh',

                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                loading...
              </Box>
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
