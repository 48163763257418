import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import config from 'src/config';

import { initFoodDetailsForNewRestaurant } from 'src/modals/food_details';

export default function useMutateInitFoodDetailForNewRestaurant() {
  const mutation = useMutation(
    'init-food-detail-for-new-restaurant',
    rest_id => {
      return initFoodDetailsForNewRestaurant(rest_id);
    }
  );

  return mutation;
}
