import { isUndefined } from 'lodash-es';

import getRestaurantById from 'src/utils/getRestaurantById';

function getServiceClassById(all_restaurants_result, rest_id) {
  let lookup_restaurant_result = getRestaurantById(
    all_restaurants_result,
    rest_id
  );

  if (isUndefined(lookup_restaurant_result)) return undefined;

  console.debug('lookup_restaurant_result', lookup_restaurant_result);

  return lookup_restaurant_result.meny_service_class;
}

export default getServiceClassById;
