import React from 'react';

import Slide from '@material-ui/core/Slide';

import { makeStyles } from '@material-ui/core';

import { LYNKED_RED } from 'src/consts/colors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  user_input_error: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  field_error_highlight: {
    color: LYNKED_RED,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { useStyles, Transition };
