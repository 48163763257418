import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isEqual } from 'lodash-es';
import { useStyles, Transition } from 'src/consts/styles';
import active_lang from 'src/langs/jp_en';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EnqueueAlert from 'src/contexts/EnqueueAlertContext';
import ImageHandler from './ImageHandler';
import ShowDebug from 'src/components/ShowDebug';
import ShowDebugJson from '../ShowDebugJson';
import TextField from '@material-ui/core/TextField';
import useFirestoreUpload from 'src/hooks/useFirestoreUpload';
import WarningIcon from '@material-ui/icons/Warning';
import { stadium_initial_value } from './stadium_initial_value';
import useUpdateStadiumInfo from 'src/hooks/useUpdateStadiumInfo';
import useMutateReserveNewStadiumId from 'src/hooks/useMutateReserveNewStadiumId';
import useMutateCreateRider from 'src/hooks/MutateCreateRider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isUndefined } from 'lodash-es';
import useQueryListAllUsers from 'src/hooks/useQueryListAllUsers';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import useQueryListAllRestaurants from 'src/hooks/useQueryListAllRestaurants';

const FIRESTORE_FILEPATH = `/stadium_info`;

export default function AddNewStadiumDialog({ open, setOpen, refetch }) {
  let classes = useStyles();
  let mutation_reserve_new_stadium_id = useMutateReserveNewStadiumId();
  let mutateFirestoreUpload = useFirestoreUpload();
  let mutationUpdateStadiumInfo = useUpdateStadiumInfo();
  let { showEnqueue } = React.useContext(EnqueueAlert);
  let list_all_user_result = useQueryListAllUsers();
  const [user_options, setUserOptions] = React.useState([]);
  let mutationCreateRider = useMutateCreateRider();
  let query_list_all_restaurants = useQueryListAllRestaurants();

  const restaurants = React.useMemo(() => {
    if (!query_list_all_restaurants.data) {
      return [];
    }

    return query_list_all_restaurants.data.map(restaurant => ({
      id: restaurant.id,
      name: restaurant.name,
    }));
  }, [query_list_all_restaurants.data]);

  React.useEffect(() => {
    if (
      isUndefined(list_all_user_result.data) ||
      isUndefined(list_all_user_result.data.users)
    ) {
      return;
    } else {
      const { users } = list_all_user_result.data;
      const option_list = users.map(entry => {
        return { uid: entry.uid, username: entry.email };
      });
      setUserOptions(option_list);
    }
  }, [list_all_user_result.data]);

  const formik = useFormik({
    initialValues: stadium_initial_value,
    validationSchema: Yup.object({
      name: Yup.string().required(active_lang.STADIUM_NAME_IS_REQUIRED),
      promotion_restaurant_id: Yup.string().when('isStadiumPromotion', {
        is: true,
        then: Yup.string().required(active_lang.STADIUM_NAME_IS_REQUIRED),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    onSubmit: async values => {
      if (!values.isStadiumPromotion) {
        values.promotion_restaurant_id = '';
      }
      let image_base64 = values.stadium_profile_pic;
      let new_rest_id = await mutation_reserve_new_stadium_id.mutateAsync();
      let file_path = `${FIRESTORE_FILEPATH}/${new_rest_id}/stadium_profile`;

      showEnqueue.warning(active_lang.TRY_CREATE_RESTAURANT);
      return mutateFirestoreUpload
        .mutateAsync({ file_path, file_base64: image_base64 })
        .then(data => {
          return mutationUpdateStadiumInfo.mutateAsync({
            rest_id: new_rest_id,
            payload: { ...values, stadium_profile_pic: data.download_url },
          });
        })
        .then(() => {
          values.riders.map(item => {
            return mutationCreateRider.mutateAsync({
              id: item.uid,
              payload: {
                stadium_id: new_rest_id,
                is_online: true,
                role: 'RIDER',
                user_name: item.username,
              },
            });
          });
          showEnqueue.success(active_lang.CREATE_RESTAURANT_DONE);
          refetch();
        })
        .then(() => {
          handleClose();
        })
        .catch(err => {
          showEnqueue.error(active_lang.ERROR_DURING_CREATE_RESTAURANT);
        });
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <DialogContent>
            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="name"
                  {...formik.getFieldProps('name')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.ADMIN_RESTAURANT_NAME}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.name && formik.errors.name ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.name}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Box mt={'1rem'}>
              <FormControl fullWidth>
                <ImageHandler
                  id="stadium_profile_pic"
                  {...formik.getFieldProps('stadium_profile_pic')}
                />
              </FormControl>
            </Box>

            <Box mt={'1rem'}>
              <Autocomplete
                id="administrators"
                {...formik.getFieldProps('administrators')}
                options={user_options}
                getOptionLabel={option => option.username}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Administrators select"
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) =>
                  formik.setFieldValue('administrators', newValue)
                }
                disableClearable
                multiple
              />
            </Box>

            <Box mt={'1rem'}>
              <Autocomplete
                id="riders"
                {...formik.getFieldProps('riders')}
                options={user_options}
                getOptionLabel={option => option.username}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Rider select"
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) =>
                  formik.setFieldValue('riders', newValue)
                }
                disableClearable
                multiple
              />
            </Box>

            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="address"
                  {...formik.getFieldProps('address')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.STADIUM_ADDRESS}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.address && formik.errors.address ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.address}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="business_name"
                  {...formik.getFieldProps('business_name')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.STADIUM_BUSINESS_NAME}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.business_name && formik.errors.business_name ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.business_name}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="registration_code"
                  {...formik.getFieldProps('registration_code')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.STADIUM_REGISTRATION_CODE}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.registration_code &&
                formik.errors.registration_code ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.registration_code}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="phone"
                  {...formik.getFieldProps('phone')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.STADIUM_PHONE}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.phone}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Box mt="1rem">
              <FormControl fullWidth>
                <TextField
                  id="service_name"
                  {...formik.getFieldProps('service_name')}
                  className={classes.TextInput}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ style: { padding: '1rem' } }}
                  label={active_lang.ADMIN_RESTAURANT_SERVICE_NAME}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.service_name && formik.errors.service_name ? (
                  <Box className={classes.field_error_highlight}>
                    <Box pr="1rem">
                      <WarningIcon fontSize="small" />
                    </Box>
                    <Box>{formik.errors.service_name}</Box>
                  </Box>
                ) : null}
              </FormControl>
            </Box>

            <Grid item xs={12} lg={6}>
              <Box mt="1rem">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isStadiumPromotion"
                        checked={formik.values.isStadiumPromotion ?? false}
                        onChange={formik.handleChange}
                      />
                    }
                    label="ENABLE PROMOTION"
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box mt="1rem">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enable_sales_count"
                        checked={formik.values.enable_sales_count ?? false}
                        onChange={formik.handleChange}
                      />
                    }
                    label={active_lang.ENABLE_SALES_COUNT}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box mt="1rem">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isSeeAll"
                        checked={formik.values.isSeeAll ?? false}
                        onChange={formik.handleChange}
                      />
                    }
                    label="ENABLE SEE ALL"
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box mt="1rem">
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enable_terms"
                        checked={formik.values.enable_terms ?? false}
                        onChange={formik.handleChange}
                      />
                    }
                    label="ENABLE TERMS"
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box mt="1rem">
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {active_lang.ENABLE_MENU_QUANTITY_CONTROL}
                  </FormLabel>
                  <FormGroup
                    style={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                        paddingTop: '8px',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="enable_food_quantity_control"
                            checked={
                              isUndefined(formik.values) ||
                              isUndefined(
                                formik.values.enable_food_quantity_control
                              )
                                ? false
                                : formik.values.enable_food_quantity_control
                            }
                            {...formik.getFieldProps(
                              'enable_food_quantity_control'
                            )}
                            inputProps={{
                              'aria-label': active_lang.FOOD,
                            }}
                          />
                        }
                        label={active_lang.FOOD}
                      />
                      {formik.values.enable_food_quantity_control && (
                        <TextField
                          id="max_food_quantity"
                          {...formik.getFieldProps('max_food_quantity')}
                          className={classes.TextInput}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{
                            style: { padding: '1rem' },
                            type: 'number',
                          }}
                          label={active_lang.MAX_FOOD_QUANTITY}
                          variant="outlined"
                          fullWidth
                          value={formik.values.max_food_quantity}
                          onChange={e =>
                            formik.setFieldValue(
                              'max_food_quantity',
                              Number(e.target.value)
                            )
                          }
                        />
                      )}
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="enable_drink_quantity_control"
                            checked={
                              isUndefined(formik.values) ||
                              isUndefined(
                                formik.values.enable_drink_quantity_control
                              )
                                ? false
                                : formik.values.enable_drink_quantity_control
                            }
                            {...formik.getFieldProps(
                              'enable_drink_quantity_control'
                            )}
                            inputProps={{
                              'aria-label': active_lang.DRINK,
                            }}
                          />
                        }
                        label={active_lang.DRINK}
                      />
                      {formik.values.enable_drink_quantity_control && (
                        <TextField
                          id="max_drink_quantity"
                          {...formik.getFieldProps('max_drink_quantity')}
                          className={classes.TextInput}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{
                            style: { padding: '1rem' },
                            type: 'number',
                          }}
                          label={active_lang.MAX_DRINK_QUANTITY}
                          variant="outlined"
                          fullWidth
                          value={formik.values.max_drink_quantity}
                          onChange={e =>
                            formik.setFieldValue(
                              'max_drink_quantity',
                              Number(e.target.value)
                            )
                          }
                        />
                      )}
                    </Box>
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>

            {formik.values.isStadiumPromotion && (
              <Box mt="1rem">
                <FormControl fullWidth>
                  <Autocomplete
                    id="promotion_restaurant_id"
                    {...formik.getFieldProps('promotion_restaurant_id')}
                    options={restaurants}
                    value={
                      restaurants.find(
                        stadium =>
                          stadium.id === formik.values.promotion_restaurant_id
                      ) || null
                    }
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="PromotionRestaurantSelect"
                        variant="outlined"
                      />
                    )}
                    disableClearable
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'promotion_restaurant_id',
                        newValue.id
                      );
                    }}
                  />
                </FormControl>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              id="cancel-new-restaurant"
              onClick={() => {
                formik.resetForm();
                handleClose();
              }}
              type="button"
              color="primary"
            >
              {active_lang.ADMIN_USER_UPDATE_CANCEL}
            </Button>
            <Button
              id="submit-new-restaurant"
              disabled={
                formik.isSubmitting ||
                !formik.dirty ||
                !isEqual(formik.errors, {})
              }
              type={'submit'}
              color="primary"
              variant="contained"
            >
              {active_lang.ADMIN_USER_UPDATE_OK}
            </Button>
          </DialogActions>
        </form>
        <ShowDebug>
          <ShowDebugJson>{JSON.stringify(formik.values)}</ShowDebugJson>
          <ShowDebugJson>{formik.isSubmitting}</ShowDebugJson>
          <ShowDebugJson>{!formik.dirty}</ShowDebugJson>
          <ShowDebugJson>{!isEqual(formik.errors, {})}</ShowDebugJson>
          <ShowDebugJson>
            {formik.isSubmitting || !isEqual(formik.errors, {})}
          </ShowDebugJson>
        </ShowDebug>
      </Dialog>
    </>
  );
}
