import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import ShowDebugJson from 'src/components/ShowDebugJson';
import active_lang from 'src/langs/jp_en';
import Actions from './Actions';
import { getStadiumUrl, subscribeAllStadiums } from 'src/modals/stadium_config';
import firebase from 'firebase/app';
import 'firebase/storage';
import Papa from 'papaparse';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export default function ListStadiums({
  className,
  stadiums,
  query_list_all_stadiums,
  ...rest
}) {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [open_upload_file, setOpenUploadFile] = React.useState([]);
  const [listStadiun, setListStadiun] = React.useState([]);

  const fetchCSVFromStorage = async stadium_id => {
    try {
      const storageRef = firebase.storage().ref(`stadium/${stadium_id}`);
      const url = await storageRef.getDownloadURL();

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.text();
      return await parseCSV(data);
    } catch (error) {
      return [];
    }
  };

  const parseCSV = file => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: results => {
          const data = results.data;
          const ids = [];

          for (let row = 1; row < data.length; row++) {
            const rowData = data[row];
            const lastColumnValue = rowData[rowData.length - 1];
            ids.push(lastColumnValue);
          }
          resolve(ids);
        },
        header: false,
        error: error => {
          reject(error);
        },
      });
    });
  };

  React.useEffect(() => {
    const unsubscribe = subscribeAllStadiums().onSnapshot(
      async querySnapshot => {
        const stadiums = [];
        const options = [];
        const fileUpload = [];
        const openUploadFile = [];
        let stadium_customer = '';

        const stadium = await getStadiumUrl();
        stadium_customer = stadium?.data()?.stadium_customer;

        querySnapshot.forEach(doc => {
          stadiums.push({ id: doc.id, data: doc.data() });
        });

        await Promise.all(
          stadiums
            .sort((a, b) => a.data.name.localeCompare(b.data.name))
            .map(async d => {
              const qrList = await fetchCSVFromStorage(d.id);
              options.push({
                name: d.data.name,
                id: d.id,
                data: d.data,
                qrList: qrList,
                stadium_customer,
              });
              fileUpload.push(qrList);
              openUploadFile.push(false);
            })
        );

        setListStadiun(options);
        setFiles(fileUpload);
        setOpenUploadFile(openUploadFile);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}></Box>
        </PerfectScrollbar>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={1}>{active_lang.STADIUM_ID}</TableCell>
              <TableCell key={0}>{active_lang.STADIUM_NAME}</TableCell>
              <TableCell key={2} style={{ textAlign: 'right' }}>
                {active_lang.ADMIN_USER_ACTIONS}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {listStadiun.map((stadiums_id_and_data, index) => {
              let stadium = stadiums_id_and_data.data;
              let stadium_id = stadiums_id_and_data.id;

              return (
                <>
                  <TableRow name={stadium_id} hover key={stadium_id}>
                    <TableCell>{stadium_id}</TableCell>
                    <TableCell>{stadium.name}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      <Actions
                        stadium_id={stadium_id}
                        customer_id_and_data={stadiums_id_and_data}
                        query_list_all_stadiums={query_list_all_stadiums}
                        files={files}
                        setFiles={setFiles}
                        open_upload_file={open_upload_file}
                        setOpenUploadFile={setOpenUploadFile}
                        index={index}
                      />
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <ShowDebugJson>{JSON.stringify(stadiums)}</ShowDebugJson>
    </>
  );
}
