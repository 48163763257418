import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';
import { UserPlus } from 'react-feather';

import AddAdvertisementDialog from 'src/components/AddAdvertisementDialog';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  refetch,
  all_restaurants_result,
  all_food_details_result,
  all_advertisement_result,
  ...rest
}) => {
  const classes = useStyles();
  let [open_new_user_dialog, setOpenNewUserDialog] = React.useState(false);
  let [user_info, setUserInfo] = React.useState({
    name: '',
    email: '',
    remarks: '',
  });

  let [disable_add_button, setDisableAddButton] = React.useState(true);

  React.useEffect(() => {
    let lists_loading =
      all_advertisement_result.isLoading || all_restaurants_result.isLoading;
    setDisableAddButton(lists_loading);
    // alert(`setDisableAddButton ${lists_loading}`);
  }, [all_advertisement_result.isLoading, all_restaurants_result.isLoading]);

  return (
    <>
      <AddAdvertisementDialog
        open={open_new_user_dialog}
        setOpen={setOpenNewUserDialog}
        all_restaurants_result={all_restaurants_result}
        all_food_details_result={all_food_details_result}
        all_advertisement_result={all_advertisement_result}
        refetch={refetch}
      />
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <div style={{ display: 'none' }}>
            <Button className={classes.importButton}>Import</Button>
            <Button className={classes.exportButton}>Export</Button>
          </div>
          <Button
            disabled={disable_add_button}
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={e => {
              setOpenNewUserDialog(true);
            }}
          >
            {active_lang.AD_ADD_NEW_ADS}
          </Button>
        </Box>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
