import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: '150px',
    maxWidth: '15vw',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export { useStyles };
