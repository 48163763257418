import firebase from "firebase/app";
import "firebase/auth";

const fb_config = {
  apiKey: "AIzaSyDK21LG5IV2ajFzVa1V0jZhAxR4ar37mrk",
  authDomain: "lynked-demo-tryout.firebaseapp.com",
  projectId: "lynked-demo-tryout",
  storageBucket: "lynked-demo-tryout.appspot.com",
  messagingSenderId: "335675372584",
  appId: "1:335675372584:web:ab02b5849ff52bb5f1711d",
  measurementId: "G-E4KY2LRKDF"
};

const app = firebase.initializeApp(fb_config)

export default app;
