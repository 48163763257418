import 'firebase/firestore';
import db_config from '../configs/db_config';
import db from '../Firebase/db';

const USER_REF = db.collection(db_config.DB_TABLE_MANAGE_USERS);

function createRider(id, payload) {
  return USER_REF.doc(id).set({ ...payload }, { merge: true });
}

async function deleteRider(stadium_id, riders) {
  const riderUIDsToDelete = riders.map(rider => rider.uid);

  const snapshot = await USER_REF.where('stadium_id', '==', stadium_id).get();

  const deleteOperations = snapshot.docs.map(async doc => {
    const riderId = doc.id;
    if (!riderUIDsToDelete.includes(riderId)) {
      await USER_REF.doc(riderId).delete();
    }
  });

  await Promise.all(deleteOperations);
}

export { createRider, deleteRider };
