import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import active_lang from 'src/langs/jp_en';
import firebase from 'firebase/app';
import 'firebase/storage';
import useUpdateStadiumInfo from 'src/hooks/useUpdateStadiumInfo';
import { useSnackbar } from 'notistack';
import { ENQUEUE_SUCCESS_CONFIG } from 'src/consts/ENQUEUE_CONFIG';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadFileDialog({
  open,
  setOpen,
  stadium_info,
  files,
  index,
  refetch,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let mutationUpdateStadiumInfo = useUpdateStadiumInfo();
  const [file, setFile] = useState(null);

  const handleClose = () => {
    setOpen(prevState => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleUpload = () => {
    if (!file) return;
    let storageRef = firebase.storage().ref(`stadium/${stadium_info.id}`);

    storageRef
      .put(file)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      })
      .then(downloadURL => {
        mutationUpdateStadiumInfo.mutateAsync({
          rest_id: stadium_info.id,
          payload: { fileUrl: downloadURL },
        });
        setFile(null);
        setOpen(false);
        enqueueSnackbar(
          active_lang.RESTAURANT_CONFIG_UPDATE_SUCCESS,
          ENQUEUE_SUCCESS_CONFIG
        );
        refetch();
      })
      .catch(error => {
        console.error('Upload failed:', error);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <Box>Upload File</Box>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <input
                accept=".csv"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '8px',
                }}
                htmlFor="contained-button-file"
              >
                <Button variant="contained" color="primary" component="span">
                  Upload CSV File
                </Button>
              </label>
              {(files[index].length > 0 || file) && (
                <Typography
                  variant="body2"
                  style={{ textAlign: 'center', marginTop: '10px' }}
                >
                  {stadium_info.id}.csv
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={!file}
                style={{ marginTop: '10px' }}
              >
                Upload to Firebase
              </Button>
            </CardContent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
