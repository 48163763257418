import React from 'react';

import { isUndefined } from 'lodash-es';
import FormMenyAdConfig from './FormMenyAdConfig';
import FormMenyLightAdConfig from './FormMenyLightAdConfig';

import active_lang from 'src/langs/jp_en';

export default function FormAdConfig({
  service_class,
  formik,
  food_details,
  all_restaurants_result,
  all_advertisement_result,
}) {
  const SwitchForm = ({ service_class }) => {
    if (isUndefined(service_class)) {
      return <>{active_lang.PLEASE_SELECT_A_RESTAURANT}</>;
    }

    let service_id = service_class.id;
    switch (service_id) {
      case 32:
        return (
          <>
            <FormMenyAdConfig
              formik={formik}
              food_details={food_details}
              all_restaurants_result={all_restaurants_result}
              all_advertisement_result={all_advertisement_result}
            />
          </>
        );
      case 4:
        return (
          <>
            <FormMenyLightAdConfig
              formik={formik}
              food_details={food_details}
              all_restaurants_result={all_restaurants_result}
              all_advertisement_result={all_advertisement_result}
            />
          </>
        );
      case 16:
        return <>temporary unavailable</>;
      default:
        return <>{active_lang.PLEASE_SELECT_A_RESTAURANT}</>;
    }
  };

  return (
    <>
      <SwitchForm service_class={service_class} />
    </>
  );
}
