import { useMutation } from 'react-query';
import { updateAdvertisementById } from 'src/modals/advertisement_config';

export default function useMutateUpdateAdvertisementById() {
  const mutation = useMutation(({ advertisement_id, payload }) => {
    console.log(
      'useMutateUpdateAdvertisementById',
      'advertisement_id',
      advertisement_id
    );
    console.log('useMutateUpdateAdvertisementById', 'payload', payload);
    return updateAdvertisementById(advertisement_id, payload);
  });

  return mutation;
}
