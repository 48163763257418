import { useMutation } from 'react-query';
import { createRider } from 'src/modals/user_config';

export default function useMutateCreateRider() {
  const mutation = useMutation(({ id, payload }) => {
    return createRider(id, payload);
  });

  return mutation;
}
