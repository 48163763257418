import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import AccountView from 'src/views/account/AccountView';
import AdvertisementRestaurantTags from 'src/views/AdvertisementRestaurantTags';
import LoginView from 'src/views/auth/LoginView';
import LogoutView from 'src/views/auth/LogoutView';
import RegisterView from 'src/views/auth/RegisterView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DocumentationView from 'src/views/DocumentationView';
import EditAdvertisementInfo from 'src/views/EditAdvertisementInfo';
import EditRestaurantInfo from 'src/views/EditRestaurantInfo';
import EditStadiumInfo from 'src/views/EditStadiumIfo';
import EditUserInfo from 'src/views/EditUserInfo';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import DashboardView from 'src/views/reports/DashboardView';
import SettingsView from 'src/views/settings/SettingsView';
import GoogleSignIn from 'src/views/SignIn/GoogleSignIn';

const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

function GithubLouiscklaw() {
  window.location.href = 'https://www.github.com/louiscklaw';
  return null;
}

const routes = [
  {
    path: `${ENV_PUBLIC_URL}/app`,
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'documentation', element: <DocumentationView /> },
      {
        path: 'edit_user_info',
        element: <EditUserInfo />,
      },
      {
        path: 'edit_restaurant_info',
        element: <EditRestaurantInfo />,
      },
      {
        path: 'edit_stadium_info',
        element: <EditStadiumInfo />,
      },
      {
        path: 'edit_advertisement_info',
        element: (
          <>
            <EditAdvertisementInfo />
          </>
        ),
      },
      // {
      //   path: 'edit_restaurant_advertisement_info',
      //   element: (
      //     <>
      //       <EditRestaurantAdvertisementInfo />
      //     </>
      //   ),
      // },
      // {
      //   path: 'edit_advertisement_info_resume',
      //   element: (
      //     <>
      //       <EditRestaurantAdvertisementInfo />
      //     </>
      //   ),
      // },
      {
        path: 'edit_advertisement_info_work_in_progress',
        element: <EditAdvertisementInfo />,
      },
      {
        path: 'advertisement_restaurant_tags',
        element: <AdvertisementRestaurantTags />,
      },

      { path: '*', element: <Navigate to={`${ENV_PUBLIC_URL}/404`} /> },
    ],
  },
  {
    path: `${ENV_PUBLIC_URL}/`,
    element: <MainLayout />,
    children: [
      { path: 'github_louiscklaw', element: <GithubLouiscklaw /> },
      { path: 'google_signin', element: <GoogleSignIn /> },
      // { path: 'terms_and_conds', element: <TermsAndConds /> },
      { path: 'login', element: <LoginView /> },
      { path: 'logout', element: <LogoutView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      {
        path: '/',
        element: <Navigate to={`${ENV_PUBLIC_URL}/app/dashboard`} />,
      },
      { path: '*', element: <Navigate to={`${ENV_PUBLIC_URL}/404`} /> },
    ],
  },
];

export default routes;
