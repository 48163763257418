import React, { useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import config from 'src/config';

import { reserveNewRestaurant } from 'src/modals/restaurant_config';

export default function useMutateReserveNewRestaurantId() {
  const mutation = useMutation('reserve-new-restaurant-id', async () => {
    return reserveNewRestaurant();
  });

  return mutation;
}
