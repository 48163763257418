import { useMutation } from 'react-query';
import { deleteRider } from 'src/modals/user_config';

export default function useMutateDeleteRider() {
  const mutation = useMutation(({ stadium_id, riders }) => {
    return deleteRider(stadium_id, riders);
  });

  return mutation;
}
