import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';
import { UserPlus } from 'react-feather';

import AddNewAdvertisementFoodDetailDialog from './AddNewAdvertisementFoodDetailDialog';

import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  handleListAllAdvertisementFoodDetails,
  ...rest
}) => {
  const classes = useStyles();
  let [open_new_user_dialog, setOpenNewUserDialog] = React.useState(false);
  let [user_info, setUserInfo] = React.useState({
    name: '',
    email: '',
    remarks: '',
  });

  return (
    <>
      <AddNewAdvertisementFoodDetailDialog
        open={open_new_user_dialog}
        setOpen={setOpenNewUserDialog}
        handleListAll={handleListAllAdvertisementFoodDetails}
      />
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-end">
          <div style={{ display: 'none' }}>
            <Button className={classes.importButton}>Import</Button>
            <Button className={classes.exportButton}>Export</Button>
          </div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<UserPlus />}
            onClick={e => {
              setOpenNewUserDialog(true);
            }}
          >
            {active_lang.ADMIN_ADD_NEW_ADVERTISEMENT}
          </Button>
        </Box>
        {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
      </div>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
