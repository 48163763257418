import React from 'react';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import ShowDebug from 'src/components/ShowDebug';
import active_lang from 'src/langs/jp_en';
import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';
import { isNull, isUndefined } from 'lodash-es';
import useMutateUserDeleteStadiumById from 'src/hooks/useMutateUserDeleteStadiumById';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ConfirmDeleteStadiumDialog({
  open,
  setOpen,
  stadium_to_delete,
  refetch,
}) {
  const { enqueueSnackbar } = useSnackbar();
  let mutationUserDeleteStadiumById = useMutateUserDeleteStadiumById();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmUserDeleteRestaurant = () => {
    if (isUndefined(stadium_to_delete) || isNull(stadium_to_delete)) {
    } else {
      mutationUserDeleteStadiumById
        .mutateAsync(stadium_to_delete.id)
        .then(() => {
          enqueueSnackbar(active_lang.DELETE_RESTAURANT_DONE, {
            variant: 'error',
          });

          refetch();
          handleClose();
        })
        .catch(err => {
          console.log('ConfirmDeleteRestaurantDialog', 'err', err);
          enqueueSnackbar(active_lang.ERROR_DURING_DELETE_RESTAURANT, {
            variant: 'error',
          });
        })
        .finally(() => {});
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth={'lg'}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ShowDebug></ShowDebug>
      <DialogTitle id="alert-dialog-slide-title">
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Box>{active_lang.ASK_DELETE_RESTAURANT}</Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogActions>
        <Button disabled={false} onClick={handleClose} color="primary">
          {active_lang.ADMIN_USER_UPDATE_CANCEL}
        </Button>
        <Button
          disabled={false}
          onClick={handleConfirmUserDeleteRestaurant}
          variant={'contained'}
          style={{ backgroundColor: LYNKED_RED, color: LYNKED_WHITE }}
        >
          {active_lang.ADMIN_USER_UPDATE_OK}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
