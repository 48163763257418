import React from 'react';

import AdminSelectMenyAdConfigureSlot from './AdminSelectMenyAdConfigureSlot';
import ShowDebug from 'src/components/ShowDebug';

import AdConfigureSlotSubform from './AdConfigureSlotSubform';

export default function FormMenyAdConfig({
  formik,
  food_details,
  all_restaurants_result,
  all_advertisement_result,
}) {
  return (
    <>
      <ShowDebug>FormMenyAdConfig</ShowDebug>
      <AdminSelectMenyAdConfigureSlot
        formik={formik}
        all_advertisement_result={all_advertisement_result}
      />
      <AdConfigureSlotSubform formik={formik} food_details={food_details} />
    </>
  );
}
