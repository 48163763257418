import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function AccountSegregation() {
  return (
    <>
      <Box mt="3rem" ml="3rem" mr="3rem">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Account segregation/levels
        </Typography>
        <Typography color="textPrimary" gutterBottom variant="h6">
          meny light
        </Typography>
        <ul>
          <li>help request</li>
          <li>menu management</li>
        </ul>
        <Typography color="textPrimary" gutterBottom variant="h6">
          meny carryout
        </Typography>
        <ul>
          <li>orders</li>
          <li>menu management</li>
        </ul>
        <Typography color="textPrimary" gutterBottom variant="h6">
          meny in
        </Typography>
        <ul>
          <li>help request</li>
          <li>orders</li>
          <li>menu management</li>
        </ul>
        <Typography color="textPrimary" gutterBottom variant="h6">
          meny
        </Typography>
        <ul>
          <li>help request</li>
          <li>orders</li>
          <li>reception list</li>
          <li>billing management</li>
          <li>menu management</li>
        </ul>
      </Box>
    </>
  );
}
