import React from 'react';
import ReactQuill from 'react-quill';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ShowDebugJson from 'src/components/ShowDebugJson';
import config from 'src/config';
import active_lang from 'src/langs/jp_en';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl } from '@material-ui/core';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['clean'],
  ],
};

const genders = [
  {
    id: 0,
    value: 'None',
  },
  {
    id: 1,
    value: 'Male',
  },
  {
    id: 2,
    value: 'Female',
  },
];

export default function EditUserDialog({
  open,
  setOpen,
  edit_user,
  handleListAllUsers,
}) {
  let [user_service_category, setUserServiceCategory] = React.useState(1);
  let [user_remarks, setUserRemarks] = React.useState('');
  let [edit_user_password, setEditUserPassword] = React.useState('');
  const [gender, setGender] = React.useState(genders[0]);

  let [show_edit_user, setShowEditUser] = React.useState({
    email: '',
    password: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitChange = () => {
    let payload = {
      uid: show_edit_user.uid,
      fields_to_update: {
        customClaims: {
          ...edit_user?.customClaims,
          gender: gender.id,
        },
      },
    };

    if (edit_user_password.trim() != '') {
      fetch(config.UPDATE_USER_PASSWORD_ENDPOINT, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: show_edit_user.uid,
          password: edit_user_password,
        }),
      }).then(response => {
        setEditUserPassword('');
        setGender(genders[0]);
        return response.json();
      });
    }

    fetch(config.UPDATE_USER_ENDPOINT, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .catch(err => {
        alert('error during update user');
      });
    alert('user update done');
    handleListAllUsers();
    handleClose();
  };

  const handleChange = e => {
    let e_name = e.target.name;
    // document.forms['edit-user-form'][e_name].value = e.target.value;
    let current_value = show_edit_user;
    current_value[e_name] = e.target.value;
    setShowEditUser(current_value);
  };

  const handlePasswordChange = e => {
    setEditUserPassword(e.target.value);
  };

  React.useEffect(() => {
    if (edit_user != null) {
      setShowEditUser(edit_user);
      console.log('edit_user', edit_user);
      if (Object.keys(edit_user).includes('customClaims')) {
        setUserServiceCategory(edit_user.customClaims.meny_class);
        const genderId = edit_user?.customClaims?.gender ?? 0;
        setGender(genders.find(g => g.id === genderId) ?? genders[0]);
      } else {
        setUserServiceCategory(0);
        setGender(genders[0]);
      }
    }
  }, [edit_user]);

  React.useEffect(() => {
    setEditUserPassword('');
    setGender(genders[0]);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <Box>{active_lang.ADMIN_USER_ACTIONS}</Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CardContent>
              {/* card content */}
              <Grid container spacing={1}>
                <form name="edit-user-form">
                  <Grid item md={12} sm={12}>
                    {/* user email  */}
                    <Grid item md={12} xs={12}>
                      <Box m="0.5rem">
                        <TextField
                          fullWidth
                          label={active_lang.ADMIN_USER_EMAIL}
                          name="email"
                          required
                          value={show_edit_user.email}
                          defaultValue={show_edit_user.email}
                          disabled
                        />
                      </Box>
                    </Grid>
                    {/* user email */}

                    {/* password  */}
                    <Grid item md={12} xs={12}>
                      <Box m="0.5rem">
                        <TextField
                          fullWidth
                          label={active_lang.ADMIN_USER_PASSWORD}
                          name="password"
                          onInput={handlePasswordChange}
                          required
                          value={edit_user_password}
                          defaultValue={edit_user_password}
                          variant="outlined"
                          style={{ width: '100%' }}
                        />
                        insert password to change, empty to keep unchanged
                      </Box>
                    </Grid>
                    {/* password */}

                    <Grid item md={12} xs={12}>
                      <Box m="0.5rem">
                        <FormControl fullWidth>
                          <Autocomplete
                            id="genders"
                            options={genders}
                            value={gender}
                            getOptionLabel={option => option.value}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="GenderSelect"
                                variant="outlined"
                              />
                            )}
                            disableClearable
                            onChange={(event, newValue) => {
                              setGender(newValue);
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* user plan, user_service_category */}
                    {/* <Grid item md={12} xs={12}>
                      <Box m="0.5rem">
                        <UserServiceCategorySelect user_service_category={user_service_category} setUserServiceCategory={setUserServiceCategory} />
                      </Box>
                    </Grid> */}
                    {/* user plan, user_service_category */}

                    {/* remarks  */}
                    <div
                      style={{
                        display:
                          process.env.NODE_ENV == 'development'
                            ? 'block'
                            : 'none',
                        backgroundColor:
                          process.env.NODE_ENV == 'development'
                            ? 'gold'
                            : 'unset',
                      }}
                    >
                      <Grid item md={12} xs={12}>
                        <Box m="1rem">
                          <Box>{active_lang.ADMIN_USER_REMARKS}</Box>
                          <Box>
                            <ReactQuill
                              theme="snow"
                              value={user_remarks}
                              onChange={setUserRemarks}
                              modules={modules}
                              disabled
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </div>
                    {/* remarks */}
                  </Grid>
                </form>
                <ShowDebugJson>{show_edit_user}</ShowDebugJson>
              </Grid>
              {/* card content */}
            </CardContent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
          <Button onClick={handleSubmitChange} color="primary">
            {active_lang.ADMIN_USER_UPDATE_OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
