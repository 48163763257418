import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import Paper from '@material-ui/core/Paper';
import GetQR from 'src/utils/GetQR';
import Pagination from './Pagination';

const default_host_ip = 'localhost';

const GridQR = ({ link, text }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <Paper className={classes.paper} style={{ padding: '0.5rem' }}>
          <GetQR link={link} text={text} style={{ padding: '10px' }} />
        </Paper>
      </Grid>
    </>
  );
};

export default function MenyQRList({ stadium_info }) {
  let { id, qrList, stadium_customer } = stadium_info;
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 32;

  const paginatedData = qrList.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  const generateRestaurantLink = tableId => {
    const restaurant_link =
      process.env.NODE_ENV !== 'development'
        ? `${stadium_customer}?seatID=${tableId}&stadiumID=${id}`
        : `http://${default_host_ip}:3000/?seatID=${tableId}&stadiumID=${id}`;
    return restaurant_link;
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={8}>
        {paginatedData.map((tableId, index) => (
          <Grid item key={index}>
            <GridQR
              link={generateRestaurantLink(tableId)}
              text={`${tableId}`}
            />
          </Grid>
        ))}
      </Grid>
      <Pagination
        data={qrList}
        rowsPerPage={rowsPerPage}
        onPageChange={setCurrentPage}
      />
    </>
  );
}
