import React, { useState } from 'react';
import { Typography, Divider, CardHeader, CardContent, Card, Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import AccountSegregation from './AccountSegregation';
import AddNewUserAccount from './AddNewUserAccount';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CustomerListView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Card>
            <CardHeader title={`Documentation`} />
            <Divider />
            <CardContent>
              <AccountSegregation />
              <AddNewUserAccount />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
