import React from 'react';
import {
  Box,
  makeStyles,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  LYNKED_RED,
  LYNKED_WHITE,
  LYNKED_GREEN,
  LYNKED_GREEN_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
} from 'src/consts/colors';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import testImageUrlValid from './testImageUrlValid';
import active_lang from 'src/langs/jp_en';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
  TextInput: {
    border: '1px solid #E0E0E0',
    font: 'normal normal bold 12px/16px Roboto',
    padding: '3px 3px',
    '& ::placeholder': {
      color: LYNKED_LIGHT_GREY,
    },
  },
  food_availability: {
    border: '1px solid #e2e2e2',
    padding: '0.5rem',
  },
  icon: {
    color: '#707070',
    marginRight: '1rem',
    marginTop: '0.25rem',
  },
  DialogBottomButtons: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Buttons: {
    width: '48%',
    padding: '10px',
    color: 'white',
    font: 'normal normal normal 11px/15px Noto Sans JP',

    letterSpacing: '0.12px',
    borderRadius: '1px',
  },
  ButtonCancel: {
    backgroundColor: LYNKED_DEEP_GREY,
    '&:hover': {
      backgroundColor: LYNKED_DEEP_GREY_HOVER,
    },
  },
  ButtonOK: {
    backgroundColor: LYNKED_GREEN,
    '&:hover': {
      backgroundColor: LYNKED_GREEN_HOVER,
    },
  },
  AddImageButton: { color: LYNKED_RED },
  AddImagePlaceHolder: {
    background: `${LYNKED_WHITE} 0% 0% no-repeat padding-box`,
    // border: `1px dashed ${LYNKED_RED}`,
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderRadius: '9px',

    width: '100%',
    minHeight: '300px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  test_icon_style: {
    color: LYNKED_RED,
  },
}));

export default function ImageHandler({ id, value, onChange, ...rest }) {
  const classes = useStyles();
  const [show_image, setShowImage] = React.useState(false);
  const file_ref = React.useRef(null);

  const handleClearImageButtonClick = () => {
    onChange({ target: { id, value: '' } });
  };

  const handleFileInput = () => {
    let selectedFile = file_ref.current.files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      onChange({ target: { id, value: e.target.result } });
    };
    reader.readAsDataURL(selectedFile);
  };

  React.useEffect(() => {
    setShowImage(testImageUrlValid(value));
  }, [value]);

  return (
    <>
      {show_image ? (
        <>
          <Box
            style={{
              width: '100%',
              height: '300px',

              backgroundImage: `url(${value})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',

              overflowX: 'hidden',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                left: '25%',
              }}
            >
              <IconButton
                type={'button'}
                onClick={handleClearImageButtonClick}
                classes={{
                  root: classes.test_icon_style,
                }}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <input
            type="file"
            onChange={handleFileInput}
            ref={file_ref}
            style={{ display: 'none' }}
          />
          <Box className={classes.AddImagePlaceHolder}>
            <Box>
              <Button
                type="button"
                onClick={() => {
                  file_ref.current.click();
                }}
                className={classes.AddImageButton}
                startIcon={<AddAPhotoIcon />}
                style={{ textAlign: 'left' }}
              >
                {active_lang.ADD_IMAGE}
                <br />
                {active_lang.ADD_IMAGE_APPENDIX}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
