import React from 'react';
import { useMutation } from 'react-query';
import config from 'src/config';

export default function MutateCreateUser() {
  const mutation = useMutation(
    'create-user',
    async ({ email, password, meny_class, gender }) => {
      const response = await fetch(config.CREATE_USER_ENDPOINT, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, meny_class, gender }),
      });

      if (!response.ok) {
        console.log('error during create users');
        return response.json();
      }

      if (!response.status != 201) {
        console.log('cannot create user');
        return response.json();
      }

      return response.json();
    }
  );

  return mutation;
}
