import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ConfirmDeleteStadiumDialog from '../ConfirmDeleteRestaurantDialog';
import ConfirmEditStadiumDialog from '../ConfirmEditStadiumDialog/ConfirmEditStadiumDialog';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import TableStadiumLinkDialog from '../ShowTableStadiumQRCodes';
import UploadFileDialog from '../UploadFileDialog';

export default function Actions({
  stadium_id,
  customer_id_and_data,
  query_list_all_stadiums,
  files,
  setFiles,
  open_upload_file,
  setOpenUploadFile,
  index,
}) {
  const [open_edit_stadium_dialog, setOpenEditStadiumDialog] = React.useState(
    false
  );
  const [
    open_table_restaurant_qr_codes,
    setOpenTableRestaurantQRCodes,
  ] = React.useState(false);

  const [
    open_confirm_delete_stadium_dialog,
    setOpenConfirmDeleteStadiumDialog,
  ] = React.useState(false);

  return (
    <>
      <ConfirmEditStadiumDialog
        open={open_edit_stadium_dialog}
        setOpen={setOpenEditStadiumDialog}
        stadium_id={stadium_id}
        initial_data={customer_id_and_data.data}
        refetch={query_list_all_stadiums.refetch}
      />

      <ConfirmDeleteStadiumDialog
        open={open_confirm_delete_stadium_dialog}
        setOpen={setOpenConfirmDeleteStadiumDialog}
        stadium_to_delete={customer_id_and_data}
        refetch={query_list_all_stadiums.refetch}
      />

      {open_upload_file[index] && (
        <UploadFileDialog
          open={open_upload_file}
          setOpen={setOpenUploadFile}
          stadium_info={customer_id_and_data}
          files={files}
          index={index}
          refetch={query_list_all_stadiums.refetch}
        />
      )}

      <TableStadiumLinkDialog
        open={open_table_restaurant_qr_codes}
        setOpen={setOpenTableRestaurantQRCodes}
        stadium_info={customer_id_and_data}
      />

      <IconButton onClick={() => setOpenEditStadiumDialog(true)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => setOpenConfirmDeleteStadiumDialog(true)}>
        <DeleteForeverIcon />
      </IconButton>

      <IconButton
        onClick={() => {
          setOpenUploadFile(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
        }}
      >
        <CloudUploadOutlined />
      </IconButton>

      <IconButton onClick={() => setOpenTableRestaurantQRCodes(true)}>
        <LinkOutlinedIcon />
      </IconButton>
    </>
  );
}
