let RESTAURANT_CONFIG_UPDATE_SUCCESS = 'RESTAURANT_CONFIG_UPDATE_SUCCESS';
let RESTAURANT_CONFIG_UPDATE_IN_PROGRESS =
  'RESTAURANT_CONFIG_UPDATE_IN_PROGRESS';
let RESTAURANT_CONFIG_UPDATE_FAILED = 'RESTAURANT_CONFIG_UPDATE_FAILED';

export default {
  RESTAURANT_CONFIG_UPDATE_SUCCESS,
  RESTAURANT_CONFIG_UPDATE_IN_PROGRESS,
  RESTAURANT_CONFIG_UPDATE_FAILED,
};
