import db_config from 'src/configs/db_config';

import db from 'src/Firebase/db';

import new_food_detail_template from 'src/modals/new_food_detail_template.json';

const FOOD_DETAIL_REF = db.collection(db_config.DB_TABLE_ALL_FOOD_DETAILS);

function listFoodDetailByRestaurantId(rest_id) {
  return FOOD_DETAIL_REF.doc(rest_id)
    .get()
    .then(doc => doc.data())
    .then(doc_data => {
      let output = [];
      let food_details = doc_data.food_details;
      Object.keys(food_details).forEach(fd_k => {
        output.push({ id: fd_k, name: food_details[fd_k].name });
      });
      return output;
    });
}

function listCategoryByRestaurantId(rest_id) {
  return FOOD_DETAIL_REF.doc(rest_id).get();
}

function initFoodDetailsForNewRestaurant(rest_id) {
  let DocRef = FOOD_DETAIL_REF.doc(rest_id);

  return db
    .runTransaction(transaction => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, new_food_detail_template, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch(error => {
      console.log('Transaction failed: ', rest_id, error);
    });
}

function getAllFoods(rest_id) {
  console.log('getAllFoods', 'rest_id', rest_id);

  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .doc(rest_id)
    .get()
    .then(querySnapshot => {
      return querySnapshot.data();
    });
}

function getAllFoodDetails() {
  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function copyFoodMenuByRestId(src_rest_id, dst_rest_id) {
  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .doc(src_rest_id)
    .get()
    .then(result => {
      return db
        .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
        .doc(dst_rest_id)
        .set(result.data());
    });
}

export {
  initFoodDetailsForNewRestaurant,
  listCategoryByRestaurantId,
  listFoodDetailByRestaurantId,
  getAllFoods,
  getAllFoodDetails,
  copyFoodMenuByRestId,
};
