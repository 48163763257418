import React from 'react';

import Grid from '@material-ui/core/Grid';

import MenyQRList from 'src/views/EditRestaurantInfo/ShowRestaurantQRCodes/RestaurantQRTest/MenyQRList';
import MenyLightQRList from 'src/views/EditRestaurantInfo/ShowRestaurantQRCodes/RestaurantQRTest/MenyLightQRList';

import { useStyles } from './style';

function SwitchQRList({ restaurant_info }) {
  switch (restaurant_info?.data?.meny_service_class?.id) {
    case 32:
      return <MenyQRList restaurant_info={restaurant_info} />;
    case 4:
      return <MenyLightQRList restaurant_info={restaurant_info} />;
    default:
      return <></>;
  }
}

export default function RestaurantQRTest({ restaurant_info }) {
  const classes = useStyles();

  // const [input_host_ip, setInputHostIp] = React.useState(default_host_ip);
  // const [host_ip, setHostIp] = React.useState(default_host_ip);
  // client
  // const [spacing, setSpacing] = React.useState(2);

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <SwitchQRList restaurant_info={restaurant_info} />
        </Grid>
      </Grid>
    </>
  );
}
