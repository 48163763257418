import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import './react-quill-style.css';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import active_lang from 'src/langs/jp_en';
import StadiumQRTest from './StadiumQRTest';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableStadiumLinkDialog({
  open,
  setOpen,
  stadium_info,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth={'lg'}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <Box>{'qr demo list'}</Box>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <CardContent>
              <StadiumQRTest stadium_info={stadium_info} />
            </CardContent>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {active_lang.ADMIN_USER_UPDATE_CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
