import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import MenyQRList from './MenyQRList';

export default function StadiumQRTest({ stadium_info }) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <MenyQRList stadium_info={stadium_info} />
        </Grid>
      </Grid>
    </>
  );
}
