import React from 'react';
import production_routes from './production_routes';

import debug_routes from './debug_routes';

let routes = production_routes;
if (process.env.NODE_ENV == 'development') {
  debug_routes.forEach(route => routes.push(route));
}

export default routes;
